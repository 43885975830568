<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Users
        </h3>
    </div>
    <div class="largeContainer">
        <div *ngIf="loading" class="centerLoading">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
        <div class="mat-elevation-z6" *ngIf="!loading">
            <div class="bg-white">
                <mat-form-field class="w-100">
                    <mat-label>Search Users</mat-label>
                    <input (keydown)="keydown()" (keyup)="keyup()"
                           (keyup.enter)="getUsers()"
                           [(ngModel)]="searchTerm"
                           matInput
                           type="text"/>
                    <button (click)="getUsers()" [disabled]="!searchTerm"
                            aria-label="Search"
                            mat-icon-button matPrefix matTooltip="Search Users">
                        <mat-icon color="primary">
                            search
                        </mat-icon>
                    </button>
                    <button (click)="clearSearch()" *ngIf="searchTerm" aria-label="Clear Search" mat-icon-button
                            matSuffix>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="flex-row gap-15 bg-white align-items-center flex-space-around filterExtraItems">

                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Role</mat-label>
                    <select matNativeControl [(ngModel)]="role" (ngModelChange)="getUsers()">
                        <option selected>All</option>
                        <option *ngFor="let userRole of roles" [selected]="role == userRole.id"
                                [value]="userRole.id">{{ userRole.name }}
                        </option>
                    </select>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Cohort</mat-label>
                    <select matNativeControl [(ngModel)]="cohort" (ngModelChange)="getUsers()">
                        <option>All</option>
                        <option *ngFor="let userCohort of cohorts" [selected]="cohort == userCohort.id"
                                [value]="userCohort.id">{{ userCohort.name }}
                        </option>
                    </select>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Locality</mat-label>
                    <select matNativeControl [(ngModel)]="locality" (ngModelChange)="getUsers()">
                        <option>All</option>
                        <option *ngFor="let local of localities" [selected]="locality == local.id"
                                [value]="local.id">{{ local.name }}
                        </option>

                    </select>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Active</mat-label>
                    <select matNativeControl [(ngModel)]="is_active" (ngModelChange)="getUsers()">
                        <option>All</option>
                        <option [value]="true">Yes</option>
                        <option [value]="false">No</option>
                    </select>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Locked</mat-label>
                    <select matNativeControl [(ngModel)]="locked" (ngModelChange)="getUsers()">
                        <option>All</option>
                        <option [value]="true">Yes</option>
                        <option [value]="false">No</option>
                    </select>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary" matTooltip="Filter users by status of their Timelines access">
                    <mat-label>Unlinked Boxes</mat-label>
                    <select matNativeControl [(ngModel)]="orphaned" (ngModelChange)="getUsers()">
                        <option>All</option>
                        <option [value]="true">Only unlinked</option>
                    </select>
                </mat-form-field>
            </div>
            <table [dataSource]="dataSource" mat-table matSort class="responsiveTable massiveTable"
                   [class.openSidebar]="this.adminView.sideNav && !this.adminView.minified"
                   (matSortChange)="sortData($event)">
                <ng-container matColumnDef="img">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let user" mat-cell>
                        <img [alt]="user.full_name" [src]="user.photo||'assets/images/blankProfile.png'"
                             class="smallProfilePicture">
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Name:</b> {{ user.full_name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="username">
                    <th *matHeaderCellDef mat-header-cell> Username</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Username:</b> {{ user.username }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="roleType">
                    <th *matHeaderCellDef mat-header-cell> Role</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Role:</b> {{ user.role }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th *matHeaderCellDef mat-header-cell> Job Title</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Job
                        Title:</b> {{ user.job_title }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="cohort">
                    <th *matHeaderCellDef mat-header-cell> Cohort</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Cohort:</b>
                        <ng-container *ngIf="user.cohorts.length > 0">
                            <ng-container *ngFor="let cohort of user.cohorts;let last = last;">{{ cohort.name }}
                                <ng-container *ngIf="!last">,</ng-container>
                            </ng-container>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="locality">
                    <th *matHeaderCellDef mat-header-cell> Locality</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Locality:</b> {{ user.locality?.name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="joined">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Date Joined</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Date
                        Joined:</b> {{ user.date_joined |date:"dd/MM/yy" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="view">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>View</th>
                    <td *matCellDef="let user" mat-cell>
                        <button mat-raised-button color="primary"
                                [routerLink]="user.timeline?.id ? ['/user', user.id, user.timeline?.id] : ['/user', user.id]">
                            View User
                        </button>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

                <tr *matNoDataRow class="mat-row">
                    <td *ngIf="searchTerm && role != 'all' && cohort != 'all' && locality != 'all' else notFound"
                        class="mat-cell text-center"
                        colspan="8">No data matching these filters
                    </td>
                </tr>
            </table>
            <mat-paginator (page)="handlePageEvent($event)" [length]="count"
                           [pageSizeOptions]="[10, 15, 25, 50]"
                           [pageSize]="limit" aria-label="Select page"></mat-paginator>
        </div>
    </div>
</main>

<ng-template #notFound>
    <td class="mat-cell text-center" colspan="8" *ngIf="!loading">No users found
    </td>
</ng-template>

<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Written Guides
        </h3>
    </div>

    <div class="mediumContainer">
        <div class="w-100 d-flex flex-col align-items-center" *ngIf="loading || !loading && trainingCentre.length == 0">
            <mat-spinner color="primary" *ngIf="loading"></mat-spinner>
            <h2 *ngIf="!loading && trainingCentre.length == 0">Sorry no content has been created for your user type yet
                please try again later</h2>
        </div>
        <div class="memoriesContainer">
            <mat-card *ngFor="let resource of trainingCentre; let i = index" class="spaceContent">
                <mat-card-header>
                    <mat-card-title class="mb-0">{{ i + 1 }}. {{ resource.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div [innerHTML]="resource.content_rendered || resource.content" class="faqDesc"></div>
                </mat-card-content>
                <mat-card-actions class="d-flex flex-row flex-space-around">
                    <button color="primary" mat-raised-button (click)="openGuide(resource)">
                        View Guide
                    </button>
                    <button *ngIf="resource.attachment" (click)="link(resource.attachment, false, resource.title)"
                            (keyup.enter)="link(resource.attachment, false, resource.title)"
                            color="primary" mat-raised-button>Download
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</main>

import {Component} from '@angular/core';
import {AuthService} from "../../_services/auth.service";
import {ContentService} from "../../_services/content.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ViewGuideComponent} from "./view-guide/view-guide.component";

@Component({
    selector: 'app-written-guides',
    templateUrl: './written-guides.component.html',
    styleUrl: './written-guides.component.scss'
})
export class WrittenGuidesComponent {
    user: any;
    trainingCentre: any[] = []
    loading: boolean = true;

    constructor(private authService: AuthService,
                private contentService: ContentService,
                public dialog: MatDialog) {
        this.user = this.authService.getUser();
        this.authService.refreshUser();
        this.contentService.writtenGuides(this.user?.role?.role_type).subscribe(r => {
            this.loading = false;
            this.trainingCentre = r;
        }, e => {
            this.loading = false;
            console.error(e)
        })
    }

    link(url: string, download: boolean, name?: string): void {
        this.authService.refreshUser();
        if (download) {
            let downloadName: string = url
            if (name) {
                downloadName = name?.replace(/ /g, "_");
            }
            fetch(url)
                // check to make sure you didn't have an unexpected failure (may need to check other things here depending on use case / backend)
                .then(resp => resp.status === 200 ? resp.blob() : Promise.reject('something went wrong'))
                .then(blob => {
                    const url: string = window.URL.createObjectURL(blob);
                    const a: HTMLAnchorElement = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    // the filename you want
                    a.download = downloadName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(() => function (): void {
                    console.error('catch error')
                    window.open(url, '_blank')?.focus();
                });
        } else {
            window.open(url, '_blank')?.focus();
        }
    }

    openGuide(guide: any): void {
        const dialogRef: MatDialogRef<ViewGuideComponent> = this.dialog.open(ViewGuideComponent, {
            data: guide,
            panelClass: 'fullWidthWindow',
        });
    }
}

<mat-toolbar class="d-flex flex-space-between mat-elevation-z3" color="primary">
    <div class="flex-row align-items-center">
        <button [routerLink]="['/reporting']" aria-label="Home"
                mat-icon-button matTooltip="Home">
            <img alt="Virtual Memory Box Logo" src="assets/images/vmb.svg">
        </button>
        <p class="m-0">{{ user?.first_name | greeting }}</p>
    </div>
    <button (click)="sideNav = !sideNav" *ngIf="isMobile" aria-label="Open Side Menu"
            mat-icon-button>
        <mat-icon>menu</mat-icon>
    </button>
</mat-toolbar>
<mat-sidenav-container class="minFullHeight ">
    <mat-sidenav (closed)="sideNav = false" [mode]="mode" [opened]="sideNav">
        <mat-nav-list class="p-0" [class.minified]="minified">
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/reporting']"
                           class="align-items-center" disableRipple matTooltip="Reporting">
                <mat-icon class="pr-10" matListItemIcon>pie_chart</mat-icon>
                <div matListItemTitle>Dashboard</div>
            </mat-list-item>
            <mat-list-item (click)="openNotificationNav()" (keyup.enter)="openNotificationNav()"
                           [routerLinkActive]="['mat-background-primary', 'primaryContrast']"
                           class="align-items-center" disableRipple matTooltip="Notifications">
                <mat-icon [matBadgeHidden]="notificationsCount < 1" matBadgeColor="warn" matBadgePosition="after"
                          matBadgeSize="small" [matBadge]="notificationsCount" class="pr-10" matListItemIcon>
                    notifications
                </mat-icon>
                <div matListItemTitle>Notifications</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']" [routerLink]="['/users']"
                           class="align-items-center" disableRipple matTooltip="Users">
                <mat-icon class="pr-10" matListItemIcon>supervised_user_circle</mat-icon>
                <div matListItemTitle>Users</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']" [routerLink]="['/invitees']"
                           class="align-items-center" disableRipple matTooltip="Invitees">
                <mat-icon class="pr-10" matListItemIcon>contact_mail</mat-icon>
                <div matListItemTitle>Invitees</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/flaggedMedia']"
                           class="align-items-center" disableRipple matTooltip="Flagged Media">
                <mat-icon class="pr-10" matListItemIcon>feedback</mat-icon>
                <div matListItemTitle>Flagged Media</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/permissionRequests']"
                           class="align-items-center" disableRipple matTooltip="Permission Requests">
                <mat-icon class="pr-10" matListItemIcon>lock</mat-icon>
                <div matListItemTitle>Permission Requests</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']" [routerLink]="['/cohorts']"
                           class="align-items-center" disableRipple matTooltip="Cohort Management">
                <mat-icon class="pr-10" matListItemIcon>group_work</mat-icon>
                <div matListItemTitle>Cohort Management</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/locations']"
                           class="align-items-center" disableRipple matTooltip="Locality Management">
                <mat-icon class="pr-10" matListItemIcon>person_pin_circle</mat-icon>
                <div matListItemTitle>Locality Management</div>
            </mat-list-item>
            <mat-list-item [matMenuTriggerFor]="training"
                           class="align-items-center" disableRipple matTooltip="Learning Centre">
                <mat-icon class="pr-10" matListItemIcon>school</mat-icon>
                <div matListItemTitle>Learning Centre</div>
            </mat-list-item>
            <mat-menu #training="matMenu">
                <button mat-menu-item [routerLink]="['/faqs']">FAQs</button>
                <button mat-menu-item [routerLink]="['/resources']">Resources</button>
                <button mat-menu-item [routerLink]="['/videoGuides']">Video Guides</button>
                <button mat-menu-item [routerLink]="['/writtenGuides']">Written Guides</button>
            </mat-menu>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/exports']"
                           class="align-items-center" disableRipple matTooltip="Export Requests">
                <mat-icon class="pr-10" matListItemIcon>cloud_download</mat-icon>
                <div matListItemTitle>Export Requests</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/profileSettings']"
                           class="align-items-center" disableRipple matTooltip="Settings">
                <mat-icon class="pr-10" matListItemIcon>settings</mat-icon>
                <div matListItemTitle>Settings</div>
            </mat-list-item>

            <mat-list-item (click)="logout()" (keyup.enter)="logout()" class="align-items-center" disableRipple
                           matTooltip="Log Out">
                <mat-icon class="pr-10" matListItemIcon>exit_to_app</mat-icon>
                <div matListItemTitle>Log-out</div>
            </mat-list-item>
            <mat-list-item class="align-items-center" disableRipple (click)="minifySideWindow()">
                <mat-icon class="pr-10" matListItemIcon *ngIf="!minified" matTooltip="Collapse Side Menu">
                    keyboard_arrow_left
                </mat-icon>
                <mat-icon class="pr-10" matListItemIcon *ngIf="minified" matTooltip="Expand Side Menu">
                    keyboard_arrow_right
                </mat-icon>
                <div matListItemTitle>Collapse Side Menu</div>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav (closed)="notificationNavOpened = false;" [opened]="notificationNavOpened" class="alertMenu p-10"
                 mode="over" position="end">
        <div class="flex-row d-flex flex-space-between">
            <h3>Notifications</h3>
            <button (click)="notificationNavOpened = false;" (keyup.enter)="notificationNavOpened = false;"
                    mat-icon-button tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <mat-tab-group mat-align-tabs="start" mat-stretch-tabs="true">
            <mat-tab label="Unread">
                <mat-progress-bar *ngIf="notificationsLoading" mode="indeterminate"></mat-progress-bar>
                <p *ngIf="this.unreadNotifications && this.unreadNotifications.length <= 0&& !notificationsLoading"
                   class="text-center muted">
                    Looks Like You Are All Caught
                    Up!</p>
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let notification of this.unreadNotifications" class="my-10">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ notification.title }}
                            </mat-panel-title>
                            <mat-panel-description>
                                <small>{{ notification.created_date|dateAgo }}</small>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <p class="p-5"> {{ notification.content }}</p>
                        <div class="actionRow">
                            <a *ngIf="this.getNotificationLink(notification)"
                               [href]="this.getNotificationLink(notification)" color="primary"
                               mat-raised-button>
                                View
                                <ng-container *ngIf="notification.memory">Memory</ng-container>
                                <ng-container *ngIf="notification.comment">Comment</ng-container>
                                <ng-container *ngIf="notification.feedback">Feedback</ng-container>
                            </a>
                            <button (click)="markRead(notification)" (keyup.enter)="markRead(notification)"
                                    *ngIf="!notification.permission_change_request"
                                    color="warn"
                                    mat-raised-button>
                                Dismiss
                            </button>
                            <ng-container *ngIf="notification.permission_change_request">
                                <button (click)="rejectPermissionRequest(notification)"
                                        (keyup.enter)="rejectPermissionRequest(notification)" color="warn"
                                        mat-raised-button>
                                    Reject
                                </button>
                                <button (keyup.enter)="acceptPermissionRequest(notification)"
                                        (click)="acceptPermissionRequest(notification)" color="primary"
                                        mat-raised-button>
                                    Accept
                                </button>
                            </ng-container>

                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

            </mat-tab>
            <mat-tab label="Read">
                <mat-progress-bar *ngIf="notificationsLoading" mode="indeterminate"></mat-progress-bar>
                <p *ngIf="this.readNotifications && this.readNotifications.length <= 0 && !notificationsLoading"
                   class="text-center muted">Looks
                    Like You Are All Caught
                    Up!</p>
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let notification of this.readNotifications" class="my-10">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ notification.title }}
                            </mat-panel-title>
                            <mat-panel-description>
                                <small>{{ notification.created_date|dateAgo }}</small>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <p class="p-5"> {{ notification.content }}</p>
                        <div class="actionRow">
                            <a *ngIf="this.getNotificationLink(notification)"
                               [href]="this.getNotificationLink(notification)" color="primary"
                               mat-raised-button>
                                View
                                <ng-container
                                        *ngIf="notification.memory && !notification.comment && !notification.feedback">
                                    Memory
                                </ng-container>
                                <ng-container *ngIf="notification.comment">Comment</ng-container>
                                <ng-container *ngIf="notification.feedback">Feedback</ng-container>
                            </a>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <div *ngIf="this.canLoadMore && this.readNotifications.length > 0" class="w-100 text-center">
                    <button (click)="this.getUnreadNotes()" (keyup.enter)="getUnreadNotes()" color="primary"
                            mat-raised-button>Load More
                    </button>
                </div>
            </mat-tab>
        </mat-tab-group>

    </mat-sidenav>
    <mat-sidenav-content>
        <div class="minFullHeight adminDashboard">
            <router-outlet #outlet="outlet"></router-outlet>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>

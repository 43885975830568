import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from "../_services/auth.service";

@Component({
    selector: 'app-passthrough',
    templateUrl: './passthrough.component.html',
    styleUrls: ['./passthrough.component.scss']
})
export class PassthroughComponent implements OnInit {

    constructor(private route: ActivatedRoute,
                private readonly router: Router,
                private authService: AuthService,
    ) {
    }

    ngOnInit(): void {
        this.route.queryParams
            .subscribe(params => {
                    if (params['token']) {
                        // Pass token to hijack the user
                        this.authService.hijackUser(params['token']);
                    } else {
                        this.router.navigate(['/login']);
                    }
                }
            );
    }
}

<mat-toolbar class="d-flex flex-space-between mat-elevation-z3" color="primary">
    <button [routerLink]="['/timeline']" aria-label="Home" mat-icon-button matTooltip="Home">
        <img alt="Virtual Memory Box Logo" src="assets/images/vmb.svg">
    </button>
</mat-toolbar>
<main class="minFullHeight" role="main">
    <div class="container d-flex justify-content-center">
        <div class="d-flex flex-column">
            <div>
                <mat-card class="main-card">
                    <mat-card-content>
                        <div class="headerRow">
                            <div class="logoSection">
                                <img alt="Virtual Memory Box Logo" class="my-10 mini-logo"
                                     src="assets/images/vmb.svg">
                            </div>
                            <div class="titleSection">
                                <h2>Hello {{ user?.first_name }}, Welcome To Virtual Memory Box!</h2>
                            </div>
                            <div class="imgSection">
                                <img alt="" class="my-10 mini-logo" src="assets/images/group.svg">
                            </div>
                        </div>
                        <mat-divider class="mb-20"></mat-divider>
                        <form [formGroup]="signupForm" class="thirdGrid mt-10">
                            <div class="pb-10 px-20">
                                <img [src]="imgURL" alt="Profile Picture" class="w-100 imgControl">
                                <div class="flex-row flex-space-around">
                                    <div>
                                        <button (click)="fileInput.click()" (keyup.enter)="fileInput.click()"
                                                color="primary" mat-raised-button>
                                            <span>Select New Profile Picture</span>
                                            <input #fileInput (change)="onFileChanged($event)"
                                                   accept="image/png, image/jpeg" class="d-none"
                                                   type="file"/>
                                        </button>
                                    </div>
                                    <div *ngIf="imgURL != 'assets/images/blankProfile.png' && imgURL != this.user.photo">
                                        <button (click)="removeImage()" (keyup.enter)="removeImage()" color="warn"
                                                mat-raised-button>Cancel
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div class="fieldContainer signupForm">
                                <mat-form-field appearance="outline" color="primary">
                                    <mat-label>First Name</mat-label>
                                    <input [disabled]="true" formControlName="first_name" matInput type="text">
                                    <button aria-label="More info" mat-icon-button matSuffix
                                            matTooltip="If you believe this field is incorrect please contact an administrator to change it">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="primary">
                                    <mat-label>Last Name</mat-label>
                                    <input [disabled]="true" formControlName="last_name" matInput type="text">
                                    <button aria-label="More info" mat-icon-button matSuffix
                                            matTooltip="If you believe this field is incorrect please contact an administrator to change it">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="primary">
                                    <mat-label>Email</mat-label>
                                    <input [disabled]="true" formControlName="email" matInput type="email">
                                    <button aria-label="More info" mat-icon-button matSuffix
                                            matTooltip="If you believe this field is incorrect please contact an administrator to change it">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="primary">
                                    <mat-label>Username</mat-label>
                                    <input [disabled]="true" formControlName="username" matInput type="text">
                                    <button aria-label="More info" mat-icon-button matSuffix
                                            matTooltip="If you believe this field is incorrect please contact an administrator to change it">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                </mat-form-field>
                                <div *ngIf="this.user?.role?.role_type == 'young_person' && !this.user.dob">
                                    <mat-form-field appearance="outline" color="primary" class="w-100">
                                        <mat-label>Date Of Birth</mat-label>
                                        <input [max]="maxDate" required formControlName="dob"
                                               matInput [matDatepicker]="picker">
                                        <mat-hint>DD/MM/YYYY</mat-hint>
                                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>About Me</mat-label>
                                    <textarea #description formControlName="about" matInput
                                              maxlength="400"></textarea>
                                    <app-emoji-menu [form]="signupForm" [input]="'about'"
                                                    matSuffix></app-emoji-menu>
                                    <mat-hint align="end">{{ description?.value?.length }} / 400</mat-hint>
                                    <mat-error *ngIf="signupForm.controls['about'].hasError('required')">
                                        This field is required
                                    </mat-error>
                                </mat-form-field>
                                <mat-slide-toggle class="center" color="primary"
                                                  formControlName="emailNotifications"
                                                  ngDefaultControl>
                                    Email Notifications
                                </mat-slide-toggle>
                            </div>

                        </form>
                    </mat-card-content>
                    <mat-card-actions class="text-center">
                        <button (click)="register()" (keyup.enter)="register()"
                                [disabled]="signupForm.invalid || loading || signupForm.value['emailConfirm'] !== signupForm.value['email']"
                                class="mx-auto my-10"
                                color="primary" mat-flat-button>
                            Register
                            <mat-icon *ngIf="loading">
                                <mat-spinner color="primary"></mat-spinner>
                            </mat-icon>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>

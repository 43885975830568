<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Invites
        </h3>
    </div>
    <div class="largeContainer">
        <div *ngIf="loading" class="centerLoading">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>

        <div class="flex-row align-items-end mb-10 gap10 justify-content-end">
            <button [routerLink]="['/invite']" color="primary" mat-raised-button>Invite New Users</button>
            <ng-container *ngIf="anySelected">
                <button color="warn" mat-raised-button (click)="deactivate()">Deactivate Users</button>
                <button color="primary" mat-raised-button (click)="reSendInvite()">Re-Invite users</button>
            </ng-container>
        </div>
        <div class="mat-elevation-z6" *ngIf="!loading">
            <div class="flex-row gap-15 bg-white align-items-center flex-space-around filterItems">
                <mat-form-field class="noBottomPadding w-100">
                    <mat-label>Search Invitees</mat-label>
                    <input (keydown)="keydown()" (keyup)="keyup()"
                           (keyup.enter)="getInvitees()"
                           [(ngModel)]="searchTerm"
                           matInput
                           type="text"/>
                    <button (click)="getInvitees()" [disabled]="!searchTerm"
                            aria-label="Search"
                            mat-icon-button matPrefix matTooltip="Search Invitees">
                        <mat-icon color="primary">
                            search
                        </mat-icon>
                    </button>
                    <button (click)="clearSearch()" *ngIf="searchTerm" aria-label="Clear Search" mat-icon-button
                            matSuffix>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Role</mat-label>
                    <select matNativeControl [(ngModel)]="role" (ngModelChange)="getInvitees()">
                        <option selected>All</option>
                        <option *ngFor="let userRole of roles" [selected]="role == userRole.id"
                                [value]="userRole.id">{{ userRole.name }}
                        </option>
                    </select>
                </mat-form-field>
            </div>
            <table [dataSource]="dataSource" class="responsiveTable" mat-table matSort
                   [class.openSidebar]="this.adminView.sideNav && !this.adminView.minified"
                   (matSortChange)="sortData($event)">
                <ng-container matColumnDef="checkbox">
                    <th *matHeaderCellDef mat-header-cell>
                        <mat-checkbox (ngModelChange)="checkAll()"
                                      [(ngModel)]="allChecked"></mat-checkbox>
                    </th>
                    <td *matCellDef="let user" mat-cell>
                        <mat-checkbox (ngModelChange)="bulkOptions($event, user)"
                                      [(ngModel)]="user.checked"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="img">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let user" mat-cell>
                        <img [alt]="user.user?.full_name" [src]="user.user?.photo||'assets/images/blankProfile.png'"
                             class="smallProfilePicture">
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Name:</b> {{ user.user?.full_name || user.user?.username }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th *matHeaderCellDef mat-header-cell> Role</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Role:</b> {{ user.role }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="inviteDate">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Invite Date</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Invite
                        Date:</b> {{ user.invite_sent_date | date:"dd/MM/yy" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="invitesSent">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> # Invites Sent</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle"># Invites
                        Sent:</b> {{ user.number_of_invites_sent }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="view">
                    <th *matHeaderCellDef mat-header-cell>View</th>
                    <td *matCellDef="let user" mat-cell>
                        <button mat-raised-button color="primary"
                                [routerLink]="['/invitee', user.id]">
                            View Invite
                        </button>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                <tr *matNoDataRow class="mat-row">
                    <td *ngIf="searchTerm && role != 'all' && status != 'all' else notFound"
                        class="mat-cell text-center"
                        colspan="6">No data matching these filters
                    </td>
                </tr>
            </table>
            <mat-paginator (page)="handlePageEvent($event)" [length]="count" [pageSizeOptions]="[10, 15, 25, 50]"
                           [pageSize]="limit" aria-label="Select page"></mat-paginator>
        </div>
    </div>
</main>
<ng-template #notFound>
    <td class="mat-cell text-center" colspan="6" *ngIf="!loading">No invitees found
    </td>
</ng-template>

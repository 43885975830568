import {Component, Inject, ViewChild} from '@angular/core';
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {FormControl} from "@angular/forms";
import {AdminService} from "../../../_services/admin.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SnackbarService} from "../../../_services/snackbar.service";

@Component({
    selector: 'app-user-select',
    templateUrl: './user-select.component.html',
    styleUrl: './user-select.component.scss'
})
export class UserSelectComponent {
    @ViewChild('userInput') userInput: any;
    filteredUsers: any[] = [];
    selectedUsers: any[] = [];
    userSearchControl: FormControl = new FormControl();
    loadingUsers: boolean = false;
    title: any;
    bulkInvite: boolean = false;

    constructor(private adminService: AdminService,
                @Inject(MAT_DIALOG_DATA) private data: any,
                protected dialogRef: MatDialogRef<UserSelectComponent>,
                private snackBarService: SnackbarService,) {
        if (data?.title) {
            this.title = data.title
        }
        if (data.bulkInvite) {
            this.bulkInvite = true;
        }

        this.userSearchControl.valueChanges.subscribe(
            (value) => {
                this.loadingUsers = true;

                let filters: any = {
                    order_by: 'full_name',
                    direction: 'asc'
                }
                if (data.filter) {
                    filters[data.filter.name] = data.filter.value;
                }
                this.adminService.getAllUsers(0, 100000, value, filters).subscribe(r => {
                    let users: any[] = [];
                    users = r.results.filter((item: any, index: any): boolean => {
                        return (r.results.indexOf(item) == index);
                    });
                    this.loadingUsers = false;
                    this.filteredUsers = users
                }, e => {
                    this.snackBarService.openSnackBar(e, 'error')
                    this.loadingUsers = false;
                    this.filteredUsers = []
                })
            }
        );
    }

    selectUser(event: MatAutocompleteSelectedEvent): void {
        const user = event as any;
        this.selectedUsers.push(user);
        if (this.userInput && this.userInput.nativeElement) {
            this.userInput.nativeElement.value = '';
        }
    }

    removeUser(user: any): void {
        const i = this.selectedUsers.findIndex((x: any): boolean => x === user);
        if (i != null && i >= 0) {
            this.selectedUsers.splice(i, 1);
        }
    }

    onFileChanged(event: any): void {
        const file = event.target.files[0];
        var mimeType = file.type;
        if (mimeType.match('text/csv') == null) {
            // check if CSV
            this.snackBarService.openSnackBar("Only CSV are supported.", 'error');
            return;
        }
        var reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event): void => {
            if (reader.result) {
                if (confirm('Are you sure you want to add all users on this CSV?')) {
                    this.adminService.bulkLinkUsersToCohort(file).subscribe(r => {
                        if (r.detail?.not_found_users && Object.values(r.detail?.not_found_users).toString().length > 0) {
                            this.snackBarService.openSnackBar("The following Users were not found: " + Object.values(r.detail?.not_found_users).toString(), 'error')
                        } else {
                            this.snackBarService.openSnackBar("Users successfully added", 'success')
                        }
                    }, e => {
                        console.error(e);
                        if (e.detail?.not_found_users) {
                            this.snackBarService.openSnackBar("The following Users were not found: " + Object.values(e.detail?.not_found_users).toString(), 'error')
                        } else {
                            this.snackBarService.openSnackBar(e, 'error')
                        }
                    })
                }
            } else {
                this.snackBarService.openSnackBar("Reader result returned null.", 'error')
            }
        }
    }

    checkIfInArray(item: any, otherArray: any): boolean {
        let i
        if (otherArray) {
            i = otherArray.findIndex((x: any): boolean => x.id === item.id);
        }
        return (i != null && i >= 0);
    }


}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from '../../environments/environment';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    constructor(private http: HttpClient) {
    }

    // Users
    getAllUsers(page: number, limit: number, search?: string, filters?: any): Observable<any> {
        const offset: number = (page * limit);
        let parameters: HttpParams = new HttpParams();
        if (search) {
            parameters = parameters.set('search', search);
        }

        parameters = parameters.set('ascending', 'asc');
        parameters = parameters.set('order_by', 'date_joined');

        if (filters) {
            if (filters.cohort) {
                parameters = parameters.set('cohorts__cohort', filters.cohort);
            }
            if (filters.locality) {
                parameters = parameters.set('locality', filters.locality);
            }
            if (filters.role) {
                parameters = parameters.set('role', filters.role);
            }
            if (filters.is_active) {
                parameters = parameters.set('is_active', filters.is_active);
            }
            if (filters.locked) {
                parameters = parameters.set('locked', filters.locked);
            }
            if (filters.direction && filters.order_by) {
                if (filters.direction) {
                    parameters = parameters.set('ascending', filters.direction == 'asc');
                }
                if (filters.order_by) {
                    parameters = parameters.set('order_by', filters.order_by);
                }
            }

        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/admin/users/list/?offset=` + offset + '&limit=' + limit, {
            params: parameters,
        });
    }

    getUser(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/admin/users/${id}/`);
    }

    patchUser(id: number, body: any): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}v2/api/admin/users/${id}/`, body);
    }

    deleteUser(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}v2/api/admin/users/${id}/`);
    }

    getUserRoles(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/admin/roles/list/`);
    }

    // Invites
    getAllInvites(page: number, limit: number, search?: string, filters?: any): Observable<any> {
        const offset: number = (page * limit);
        let parameters: HttpParams = new HttpParams();
        if (search) {
            parameters = parameters.set('search', search);
        }

        parameters = parameters.set('ascending', 'asc');
        parameters = parameters.set('order_by', 'invite_sent_date');
        parameters = parameters.set('invite_status', '!accepted');
        if (filters) {
            if (filters.status) {
                parameters = parameters.set('invite_status', filters.status);
            }
            if (filters.role) {
                parameters = parameters.set('role', filters.role);
            }
            if (filters.direction && filters.order_by) {
                if (filters.direction) {
                    parameters = parameters.set('ascending', filters.direction == 'asc');
                }
                if (filters.order_by) {
                    parameters = parameters.set('order_by', filters.order_by);
                }
            }

        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/admin/invites/list/?offset=` + offset + '&limit=' + limit, {
            params: parameters,
        });
    }

    getUserInvite(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/admin/invites/${id}/`);
    }

    reInviteUsers(ids: number[]): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/invites/multi-reinvite/`, {ids});
    }

    deleteInvites(ids: number[]): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/invites/multi-delete/`, {ids});
    }

    patchInvite(id: number, body: any): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}v2/api/admin/invites/${id}/`, body);
    }

    sendInvite(body: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/invites/create/`, body);
    }

    sendInvites(file: any): Observable<any> {
        const headerAttachmentNameValue: string = 'attachment; filename="csv-invite-users"';
        const headers = {
            'Content-Disposition': headerAttachmentNameValue,
        };
        const fd: FormData = new FormData();
        if (file) {

            fd.append('csv_file', file);
        }
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/csv-bulk-invite-users-upload/`, fd, {
            headers
        });
    }


    // User Feedback
    getAllUserFeedback(page: number, limit: number, search?: string, filters?: any): Observable<any> {
        const offset: number = (page * limit);
        let parameters: HttpParams = new HttpParams();
        if (search) {
            parameters = parameters.set('search', search);
        }

        parameters = parameters.set('ascending', 'asc');
        parameters = parameters.set('order_by', 'created_date');
        if (filters) {
            if (filters.category) {
                parameters = parameters.set('category', filters.category);
            }
            if (filters.resolution_status) {
                parameters = parameters.set('resolution_status', filters.resolution_status);
            }
            if (filters.direction && filters.order_by) {
                if (filters.direction) {
                    parameters = parameters.set('ascending', filters.direction == 'asc');
                }
                if (filters.order_by) {
                    parameters = parameters.set('order_by', filters.order_by);
                }
            }
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/admin/user-feedback/list/?offset=` + offset + '&limit=' + limit, {
            params: parameters,
        });
    }

    getUserFeedback(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/admin/user-feedback/${id}/`);
    }

    updateUserFeedback(id: number, body: any): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}v2/api/admin/user-feedback/${id}/`, body);
    }

    deleteMedia(id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/user-feedback/${id}/hard-delete-media-content/`, {});
    }

    hideMedia(id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/user-feedback/${id}/soft-delete-media-content/`, {});
    }

    unHideMedia(id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/user-feedback/${id}/soft-delete-media-content/`, {undo: true});
    }

    // Timeline Access
    getUserAccessRequests(id: number, page: number, limit: number, event?: any): Observable<any> {
        const offset: number = (page * limit);
        let parameters: HttpParams = new HttpParams();

        parameters = parameters.set('offset', offset);
        parameters = parameters.set('limit', limit);
        parameters = parameters.set('ascending', 'asc');
        parameters = parameters.set('order_by', 'created_date');
        if (event?.direction && event?.active) {
            if (event.direction) {
                parameters = parameters.set('ascending', event.direction == 'asc');
            }
            if (event.active) {
                let order = event.active;
                if (order == 'createdDate') {
                    order = 'created_date'
                } else if (order == 'requestedBy') {
                    order = 'requested_by'
                }
                parameters = parameters.set('order_by', order);
            }

        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/admin/timelines/${id}/access-request/list/`, {
            params: parameters,
        });
    }

    getAccessRequests(page: number, limit: number, search?: string, event?: any, filter?: any): Observable<any> {
        const offset: number = (page * limit);
        let parameters: HttpParams = new HttpParams();
        if (search) {
            parameters = parameters.set('search', search);
        }
        parameters = parameters.set('offset', offset);
        parameters = parameters.set('limit', limit);
        parameters = parameters.set('ascending', 'asc');
        parameters = parameters.set('order_by', 'created_date');
        if (event?.direction && event?.active) {
            if (event.direction) {
                parameters = parameters.set('ascending', event.direction == 'asc');
            }
            if (event.active) {
                let order = event.active;
                if (order == 'createdDate') {
                    order = 'created_date'
                } else if (order == 'requestedBy') {
                    order = 'requested_by'
                }
                parameters = parameters.set('order_by', order);
            }
        }
        if (filter) {
            for (const [key, value] of Object.entries(filter)) {
                parameters = parameters.set(`${key}`, `${value}`);
            }
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/admin/timeline-access-request/list/`, {
            params: parameters,
        });
    }

    getAccessRequest(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/admin/timeline-access-request/${id}/`);
    }

    permissionUpdate(id: number, body: any): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}v2/api/admin/timeline-access/${id}/`, body);
    }

    newPermission(id: number, user_id: number, permission: string): Observable<any> {
        let body = {
            user_id,
            permission
        }
        return this.http.post<any>(`${environment.apiUrl}v2/api/timelines/permissions/${id}/`, body);
    }

    accessRequestUpdate(id: number, body: any): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}v2/api/admin/timeline-access-request/${id}/`, body);
    }

    // Cohorts
    getCohorts(page: number, limit: number, search?: string, filters?: any): Observable<any> {
        const offset: number = (page * limit);
        let parameters: HttpParams = new HttpParams();
        parameters = parameters.set('ascending', 'asc');
        parameters = parameters.set('order_by', 'created_date');
        if (search) {
            parameters = parameters.set('search', search);
        }
        if (filters && filters.direction && filters.order_by) {
            if (filters.direction) {
                parameters = parameters.set('ascending', filters.direction == 'asc');
            }
            if (filters.order_by) {
                parameters = parameters.set('order_by', filters.order_by);
            }
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/admin/cohorts/?offset=` + offset + '&limit=' + limit, {
            params: parameters,
        });
    }

    deleteCohort(id: number): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (id) {
            parameters = parameters.set('id', id);
        }
        return this.http.delete<any>(`${environment.apiUrl}v2/api/admin/cohorts/`, {
            params: parameters,
        });
    }

    newCohort(name: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/cohorts/`, {name: name});
    }

    addUsersToCohort(body: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/cohorts/add/`, body);
    }

    removeUsersFromCohort(body: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/cohorts/remove/`, body);
    }

    bulkLinkUsersToCohort(file: any): Observable<any> {
        const headerAttachmentNameValue: string = 'attachment; filename="csv-link-users-to-cohorts"';
        const headers = {
            'Content-Disposition': headerAttachmentNameValue,
        };
        const fd: FormData = new FormData();
        if (file) {

            fd.append('csv_file', file);
        }
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/csv-link-users-to-cohorts-upload/`, fd, {
            headers
        });
    }

    // Localities
    getLocalities(page: number, limit: number, search?: string, filters?: any): Observable<any> {
        const offset: number = (page * limit);
        let parameters: HttpParams = new HttpParams();
        parameters = parameters.set('ascending', 'asc');
        parameters = parameters.set('order_by', 'created_date');
        if (search) {
            parameters = parameters.set('search', search);
        }
        if (filters && filters.direction && filters.order_by) {
            if (filters.direction) {
                parameters = parameters.set('ascending', filters.direction == 'asc');
            }
            if (filters.order_by) {
                parameters = parameters.set('order_by', filters.order_by);
            }
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/admin/localities/?offset=` + offset + '&limit=' + limit, {
            params: parameters,
        });
    }

    deleteLocality(id: number): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (id) {
            parameters = parameters.set('id', id);
        }
        return this.http.delete<any>(`${environment.apiUrl}v2/api/admin/localities/`, {
            params: parameters,
        });
    }

    newLocality(name: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/localities/`, {name: name});
    }

    addUserToLocality(user_id: number, locality_id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/localities/user/${user_id}/${locality_id}/`, {});
    }

    removeUserFromLocality(user_id: number, locality_id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}v2/api/admin/localities/user/${user_id}/${locality_id}/`);
    }


//     Reporting

    reportingOverview(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/overview/`);
    }

    adminReports(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/admin/`);
    }

    localityReport(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/group/?locality=` + id);
    }

    cohortReport(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/group/?cohort=` + id);
    }

    usersReports(locality?: string, cohort?: string): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (cohort) {
            parameters = parameters.set('cohorts__cohort', cohort);
        }
        if (locality) {
            parameters = parameters.set('locality', locality);
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/user/`, {
            params: parameters,
        });
    }

    // Other

    exportRequests(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/timeline/exports/requests/`);
    }

    forceLogoutUser(id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/security/force-logout/`, {user: id});
    }
}

import {Component} from '@angular/core';
import {AdminService} from "../../_services/admin.service";
import {SnackbarService} from "../../_services/snackbar.service";

@Component({
    selector: 'app-reporting',
    templateUrl: './reporting.component.html',
    styleUrl: './reporting.component.scss'
})
export class ReportingComponent {
    reportingOverview: any = null;
    adminOverview: any = null;
    childrenNumber: number = 0;
    carerNumber: number = 0;

    constructor(private adminService: AdminService,
                private snackBarService: SnackbarService) {

        this.adminService.reportingOverview().subscribe(r => {
            this.reportingOverview = r;
            this.childrenNumber = (this.reportingOverview.user_types['Young Person (12-16)'].total ||0) + (this.reportingOverview.user_types['Care Leaver (16-25)'].total||0) + (this.reportingOverview.user_types['Child (under 12)'].total||0)
            this.carerNumber = (this.reportingOverview.user_types['Carer'].total ||0) + (this.reportingOverview.user_types['Social Worker'].total||0)
        }, error => {
            this.reportingOverview = {error: error}
            this.snackBarService.openSnackBar(error, 'error');
        })

        this.adminService.adminReports().subscribe(r => {
            this.adminOverview = r;
        }, error => {
            this.adminOverview = {error: error}
            this.snackBarService.openSnackBar(error, 'error');
        })
    }

}

<main>

    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Cohorts
        </h3>
    </div>
    <div class="mediumContainer">
        <div *ngIf="loading" class="centerLoading">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
        <div class="mat-elevation-z6" *ngIf="!loading">
            <div class="flex-row gap-15 bg-white align-items-center flex-space-around justify-content-center">
                <mat-form-field class="noBottomPadding w-100">
                    <mat-label>Search Cohorts</mat-label>
                    <input (keydown)="keydown()" (keyup)="keyup()"
                           (keyup.enter)="getCohorts()"
                           [(ngModel)]="searchTerm"
                           matInput
                           type="text"/>
                    <button (click)="getCohorts()" [disabled]="!searchTerm"
                            aria-label="Search"
                            mat-icon-button matPrefix matTooltip="Search Cohorts">
                        <mat-icon color="primary">
                            search
                        </mat-icon>
                    </button>
                    <button (click)="clearSearch()" *ngIf="searchTerm" aria-label="Clear Search" mat-icon-button
                            matSuffix>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field appearance="outline" class="noBottomPadding w-100 mt-10">
                    <mat-label>New Cohort</mat-label>
                    <input #cohortInput matInput placeholder="Add Cohort">
                    <button (click)="addCohort(cohortInput)" *ngIf="cohortInput.value" aria-label="Add Cohort"
                            mat-icon-button matSuffix matTooltip="Add a new cohort">
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-form-field>

            </div>
            <table [dataSource]="dataSource" class="responsiveTable" mat-table matSort
                   (matSortChange)="sortData($event)">
                <ng-container matColumnDef="name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
                    <td *matCellDef="let cohort" mat-cell>{{ cohort.name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Created Date</th>
                    <td *matCellDef="let cohort" mat-cell>
                        {{ cohort.created_date| date }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="view">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>View</th>
                    <td *matCellDef="let cohort" mat-cell>
                        <button mat-raised-button color="primary" [routerLink]="['/cohort', cohort.id]">
                            View Cohort
                        </button>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                <tr *matNoDataRow class="mat-row">
                    <td *ngIf="searchTerm" class="mat-cell text-center" colspan="2">
                        No data matching the filter "{{ searchTerm }}"
                    </td>
                    <td *ngIf="!searchTerm" class="mat-cell text-center" colspan="2">
                        No cohorts Found
                    </td>
                </tr>
            </table>
        </div>
    </div>
</main>

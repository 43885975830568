<main>
    <div class="mediumContainer">
        <div class="d-flex flex-row flex-space-between">
            <button [routerLink]="['/locations']" aria-label="Back" mat-icon-button matTooltip="Back">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
            <div class="d-flex flex-row justify-content-center">
                <h3 class="my-10">
                    {{ location?.locality?.name || 'Location' }}
                </h3>
            </div>
            <div></div>
        </div>
    </div>
    <div class="largeContainer">

        <div *ngIf="loading" class="centerLoading">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
        <div class="flex-row align-items-end mb-10 gap10 justify-content-end align-items-center" *ngIf="this.location">
            <button (click)="addUsersModal()"
                    aria-label="Add Users to location" color="primary"
                    mat-raised-button matTooltip="Add Users to location">
                Add Users
            </button>
            <ng-container *ngIf="anySelected">
                <button color="warn" mat-raised-button (click)="removeUsers()">Remove Users From Locality</button>
            </ng-container>
            <button (click)="delete()"
                    aria-label="Delete Location" color="warn"
                    mat-raised-button matTooltip="Delete Location">
                Delete Location
            </button>
        </div>
        <div class="filtersGrid mb-20">
            <mat-card class="mat-elevation-z6 flex-grow">
                <mat-card-header>
                    <mat-card-title>Usage Statistics</mat-card-title>
                </mat-card-header>
                <mat-card-content class="h-100">
                    <div *ngIf="!localityData" class="flex-row align-items-center justify-content-center">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <ng-container *ngIf="localityData">
                        <div class="flex-row pt-10 flex-space-around">
                            <apx-chart
                                    *ngIf="(localityData.total_invites_accepted  && localityData.total_invites_accepted > 0 )||
                                     (localityData.total_invites_pending && localityData.total_invites_pending > 0 )||
                                     (localityData.total_invites_expired && localityData.total_invites_expired > 0 )"
                                    [series]="[localityData.total_invites_accepted || 0, localityData.total_invites_pending|| 0, localityData.total_invites_expired||0]"
                                    [chart]="{ width: '100%', type: 'donut'}"
                                    [legend]="{show: false}"
                                    [tooltip]="{enabled: false}"
                                    [colors]="['#67B688','#FFAC1C','#D2042D']"
                                    [labels]="['Accepted', 'Pending', 'Expired']"
                            ></apx-chart>
                            <div class="d-flex flex-row gap-15 flex-wrap justify-content-center">
                                <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                     *ngIf="localityData.total_invites_accepted != null"> Accepted:
                                    <div class="statCircle success">{{ localityData.total_invites_accepted | number:'1.0-2' }}</div>
                                </div>
                                <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                     *ngIf="localityData.total_invites_pending != null"> Pending:
                                    <div class="statCircle warning">{{ localityData.total_invites_pending| number:'1.0-2' }}</div>
                                </div>
                                <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                     *ngIf="localityData.total_invites_expired != null"> Expired:
                                    <div class="statCircle danger">{{ localityData.total_invites_expired | number:'1.0-2' }}</div>
                                </div>
                                <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                     *ngIf="localityData.percentage_logged_in != null"> Logged In:
                                    <div class="statCircle black">{{ localityData.percentage_logged_in | number:'1.0-2' }}
                                        %
                                    </div>
                                </div>

                                <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                     *ngIf="localityData.total_users != null"> Total Users:
                                    <div class="statCircle black">{{ localityData.total_users | number:'1.0-2' }}</div>
                                </div>
                            </div>
                        </div>

                    </ng-container>
                </mat-card-content>
            </mat-card>
            <mat-card class="mat-elevation-z6 flex-grow">
                <mat-card-header>
                    <mat-card-title>Upload Statistics</mat-card-title>
                </mat-card-header>
                <mat-card-content class="h-100">
                    <div *ngIf="!localityData" class="flex-row align-items-center justify-content-center">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <ng-container *ngIf="localityData">
                        <div class="flex-row pt-10 flex-space-around">
                            <apx-chart *ngIf="(localityData.total_uploads.images  && localityData.total_uploads.images > 0 )||
                                        (localityData.total_uploads.video  && localityData.total_uploads.video > 0 )||
                                        (localityData.total_uploads.audio  && localityData.total_uploads.audio > 0 )||
                                        (localityData.total_uploads.documents  && localityData.total_uploads.documents > 0 )"
                                       [series]="[localityData.total_uploads.images||0, localityData.total_uploads.video||0, localityData.total_uploads.audio||0, localityData.total_uploads.documents||0]"
                                       [chart]="{ width: '100%', type: 'donut'}"
                                       [legend]="{show: false}"
                                       [tooltip]="{enabled: false}"
                                       [colors]="['#67B688','#FFAC1C','#038CC9FF', '#FFD005FF']"
                                       [labels]="['Images', 'Video', 'Audio', 'Documents']"
                            ></apx-chart>
                            <div class="d-flex flex-row gap-15 flex-wrap justify-content-center">
                                <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                     *ngIf="localityData.total_uploads.images != null"> Images:
                                    <div class="statCircle success">{{ localityData.total_uploads.images | number:'1.0-2' }}</div>
                                </div>
                                <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                     *ngIf="localityData.total_uploads.video != null"> Video:
                                    <div class="statCircle warning">{{ localityData.total_uploads.video | number:'1.0-2' }}</div>
                                </div>
                                <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                     *ngIf="localityData.total_uploads.audio != null"> Audio:
                                    <div class="statCircle primary">{{ localityData.total_uploads.audio | number:'1.0-2' }}</div>
                                </div>
                                <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                     *ngIf="localityData.total_uploads.documents != null"> Documents:
                                    <div class="statCircle accent">{{ localityData.total_uploads.documents | number:'1.0-2' }}</div>
                                </div>
                                <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                     *ngIf="localityData.total_uploads.total != null"> Total:
                                    <div class="statCircle black">{{ localityData.total_uploads.total | number:'1.0-2' }}</div>
                                </div>

                                <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                     *ngIf="localityData.percentage_uploaded != null"> Uploaded:
                                    <div class="statCircle black">{{ localityData.percentage_uploaded | number:'1.0-2' }}
                                        %
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="mat-elevation-z6" *ngIf="!loading">
            <table [dataSource]="dataSource" class="responsiveTable" mat-table matSort
                   (matSortChange)="sortData($event)">
                <ng-container matColumnDef="checkbox">
                    <th *matHeaderCellDef mat-header-cell>
                        <mat-checkbox (ngModelChange)="checkAll()"
                                      [(ngModel)]="allChecked">
                        </mat-checkbox>
                    </th>
                    <td *matCellDef="let user" mat-cell>
                        <mat-checkbox (ngModelChange)="bulkOptions($event, user)"
                                      [(ngModel)]="user.checked"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="img">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let user" [routerLink]="['/user', user.id]" class="clickable"
                        mat-cell>
                        <img [alt]="user.full_name" [src]="user.img||'assets/images/blankProfile.png'"
                             class="smallProfilePicture">
                    </td>
                </ng-container>
                <ng-container matColumnDef="full_name">
                    <th *matHeaderCellDef mat-header-cell> Name</th>
                    <td *matCellDef="let user" [routerLink]="['/user', user.id]" class="clickable"
                        mat-cell> {{ user.full_name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th *matHeaderCellDef mat-header-cell> Role</th>
                    <td *matCellDef="let user" [routerLink]="['/user', user.id]" class="clickable"
                        mat-cell> {{ user.role?.name }}
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                <tr *matNoDataRow class="mat-row">
                    <td *ngIf="!loading" class="mat-cell text-center" colspan="6">No users in location</td>
                </tr>
            </table>
        </div>
    </div>
</main>

import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {AdminService} from "../../_services/admin.service";
import {SnackbarService} from "../../_services/snackbar.service";

@Component({
    selector: 'app-locations',
    templateUrl: './locations.component.html',
    styleUrl: './locations.component.scss'
})
export class LocationsComponent implements AfterViewInit {
    displayedColumns: string[] = ['name', 'date', 'view'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
    @ViewChild(MatSort) sort: MatSort | null = null;
    limit: number = 1000;
    page: number = 0;
    count: number = 0;
    loading: boolean = true;
    typingTimer: any;
    searchTerm: any;
    direction: string | undefined;
    order_by: string | undefined;

    constructor(private adminService: AdminService,
                private snackbarService: SnackbarService) {
        this.getLocations();
    }

    getLocations(): void {
        let filters: any = {
            order_by: null,
            direction: null,
        }
        if (this.order_by && this.direction) {
            filters.order_by = this.order_by
        }
        if (this.direction) {
            filters.direction = this.direction
        }

        this.adminService.getLocalities(this.page, this.limit, this.searchTerm, filters).subscribe(r => {
            this.count = r.length;
            this.dataSource = new MatTableDataSource(r.results);
            this.loading = false;
        }, e => {
            this.loading = false;
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    keyup(): void {
        clearTimeout(this.typingTimer);
        const that: this = this;
        this.typingTimer = setTimeout((): void => {
            that.loading = true;
            this.getLocations()

            if (this.dataSource.paginator) {
                this.dataSource.paginator.firstPage();
            }
        }, 1000);
    }


    clearSearch(): void {
        this.searchTerm = '';
        this.getLocations();
    }

    keydown(): void {
        clearTimeout(this.typingTimer);
    }


    addLocation(locationInput: HTMLInputElement): void {
        this.adminService.newLocality(locationInput.value).subscribe(r => {
            this.getLocations()
        }, e => {
            console.error(e);
            this.snackbarService.openSnackBar(e, 'error')
        })
    }

    handlePageEvent(e: PageEvent): void {
        this.page = e.pageIndex;
        this.getLocations();
    }

    sortData(event: Sort): void {
        this.order_by = event.active;
        if (this.order_by == 'full_name') {
            this.order_by = 'name'
        } else if (this.order_by == 'date') {
            this.order_by = 'created_date'
        }
        this.direction = event.direction;
        this.getLocations()
    }
}

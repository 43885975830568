<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Memory Boxes
        </h3>
    </div>

    <div class="mediumContainer">
        <mat-form-field appearance="outline" class="w-100 noBottomPadding" color="primary">
            <mat-label>Search</mat-label>
            <input (keydown)="keydown()" (keyup)="keyup()"
                   (keyup.enter)="getTimelines()"
                   [(ngModel)]="searchTerm"
                   matInput
                   type="text"/>
            <button (click)="getTimelines()" (keyup.enter)="getTimelines()" [disabled]="!searchTerm"
                    aria-label="Search"
                    mat-icon-button matPrefix matTooltip="Search Memories">
                <mat-icon color="primary">
                    search
                </mat-icon>
            </button>
            <button (click)="clearSearch()" (keyup.enter)="clearSearch()" *ngIf="searchTerm" aria-label="Clear Search" mat-icon-button matSuffix>
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-progress-bar *ngIf="loading" color="primary" class="mt-10" mode="indeterminate"></mat-progress-bar>
        <div class="peopleContainer" *ngIf="!loading">
            <div *ngIf="(!timelines || timelines.length <= 0 ) && !loading" class="text-center">
                <p *ngIf="this.searchTerm else noFilters">
                    We're sorry, no Memory Boxes were found for your search: "{{ searchTerm }}".
                </p>
                <ng-template #noFilters>
                    <p>No Memory Boxes available yet!</p>
                </ng-template>
            </div>

            <div *ngFor="let timeline of timelines" class="card">
                <mat-card [routerLink]=" ['/timeline', timeline.id]"
                          [attr.aria-label]="'View ' +  timeline.child.full_name  + '\'s Memory Box'"
                          class="clickable">
                    <img [src]="timeline.child.photo || 'assets/images/blankProfile.png'"
                         alt="{{timeline.child.full_name}}'s Photo"
                         class="squareImage" mat-card-image>
                    <mat-card-header>
                        <mat-card-title *ngIf="timeline.child.id != user.id">{{ timeline.child.full_name }}
                        </mat-card-title>
                        <mat-card-title *ngIf="timeline.child.id === user.id">My Profile</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <ng-container
                                *ngIf="timeline.child.id != user.id && timeline?.child.role?.role_type != 'young_person'">
                            <small>{{ timeline.child?.job_title || timeline.child?.role?.name }}</small>
                        </ng-container>
                    </mat-card-content>
                    <mat-card-actions class="flex-col gap10 flex-space-between">
                        <button [routerLink]="['/timeline', this.timeline.id]" color="primary"
                                [attr.aria-label]="'View ' +  timeline.child.full_name  + '\'s Memory Box'"
                                mat-raised-button>View Memory Box
                        </button>
                        <button *ngIf="user.role.can_access_profiles || timeline.child.id === user.id"
                                [routerLink]="['/profile',0, timeline.id]" color="primary"
                                [attr.aria-label]="'View ' +  timeline.child.full_name  + '\'s Profile'"
                                mat-raised-button>View Profile
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</main>

import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../_services/auth.service";
import {MatDrawerMode} from "@angular/material/sidenav";
import {MatDialog} from "@angular/material/dialog";
import {ManageAccessComponent} from "../shared/manage-access/manage-access.component";
import {FeedbackComponent} from "../shared/feedback/feedback.component";
import {ContentService} from "../_services/content.service";
import {SnackbarService} from "../_services/snackbar.service";
import {GreetingPipe} from "../_pipes/greeting.pipe";

@Component({
    selector: 'app-authview',
    templateUrl: './authview.component.html',
    styleUrls: ['./authview.component.scss'],
})
export class AuthviewComponent implements OnInit, OnDestroy {
    user: any;
    isMobile: boolean = false;
    mode: MatDrawerMode = 'over';
    sideNav: boolean = false;
    notificationNavOpened: boolean = false;
    hijacking: boolean = false;
    readNotifications: any[] = [];
    unreadNotifications: any[] = [];
    notificationsRetrieved: boolean = false;
    notificationsCount: number = 0;
    notificationsPage: number = 0;
    notificationsLimit: number = 10;
    canLoadMore: boolean = true;
    notificationsLoading: boolean = true;
    timelineId: any;
    protected readonly GreetingPipe = GreetingPipe;
    interval;

    constructor(private authService: AuthService,
                public dialog: MatDialog,
                public contentService: ContentService,
                private snackBarService: SnackbarService) {
        this.user = this.authService.getUser();

        if (localStorage.getItem('hijacked')) {
            this.hijacking = true;
        }
        this.contentService.getNotificationsCount().subscribe(r => {
            this.notificationsCount = r.count;
        }, e => {
            console.error(e);
        })
        if (this.user?.role?.role_type == 'young_person') {
            // if child then hide timeline if no timelines present
            this.contentService.viewMyTimeline().subscribe(r => {
                this.timelineId = r.id
            }, e => {
                console.error(e)
            })
        }

        let that = this;
        this.interval = setInterval(function (): void {
            // Check user every minute to ensure the user hasn't timed out or been forcibly timed out by an admin
            if (that.authService.isLoggedIn()) {
                that.authService.userHeartbeat().subscribe(r => {
                }, e => {
                    console.error(e)
                })
            }
        }, 60000);
    }

    ngOnDestroy(): void {
        clearTimeout(this.interval);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        this.isMobile = window.innerWidth <= 600;
    }

    ngOnInit(): void {
        this.isMobile = window.innerWidth <= 600;
    }

    logout(): void {
        this.authService.logout();
    }

    feedback(): void {
        if (this.dialog.openDialogs.length == 0) {
            this.dialog.open(FeedbackComponent, {});
        }
    }

    manageAccess(): void {
        if (this.timelineId && this.user.id && this.dialog.openDialogs.length == 0) {
            this.dialog.open(ManageAccessComponent, {
                data: {context: 'My Timeline', id: this.timelineId, userId: this.user.id},
                panelClass: 'fullWidthWindow',
            });
        }
    }

    openNotificationNav(): void {
        this.sideNav = false;
        this.notificationNavOpened = true;
        if (!this.notificationsRetrieved) {
            this.getNotifications()
        }
    }

    getNotifications(): void {
        this.notificationsLoading = true;
        this.contentService.getActiveNotifications().subscribe(r => {
            this.unreadNotifications = r.results;
            this.notificationsLoading = false;
        }, e => {
            console.error(e);
            this.notificationsLoading = false;
            this.snackBarService.openSnackBar(e, 'error')
        })
        this.getUnreadNotes()
    }

    getUnreadNotes(): void {
        this.notificationsLoading = true;
        // Needs to specify unread
        this.contentService.getNotifications(this.notificationsPage, this.notificationsLimit).subscribe(r => {
            if (r.results.length > 0) {
                this.readNotifications = this.readNotifications.concat(r.results);
            }
            this.notificationsPage++
            if (r.count <= this.readNotifications?.length) {
                this.canLoadMore = false;
            }
            this.notificationsLoading = false;
        }, e => {
            this.notificationsLoading = false;
            console.error(e);
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

    getNotificationLink(notification: any): string | null {
        let link = null;
        if (notification.link) {
            link = notification.link;
        } else if (notification.feedback) {
            if (notification.feedback.memory_id) {
                link = '/memory/' + notification.feedback.memory_id
                if (notification.feedback.content) {
                    link = link + '/#' + notification.feedback.content;
                }
            }
        } else if (notification.memory) {
            link = '/memory/' + notification.memory.id
        } else if (notification.comment) {
            link = '/memory/' + notification.comment.memory.id
        }
        return link
    }

    markRead(notification: any): void {
        this.contentService.markNotificationRead(notification.id).subscribe(r => {
            const i: number = this.unreadNotifications.findIndex((x) => x === notification);
            this.unreadNotifications.splice(i, 1);
            if (this.notificationsCount > 0) {
                this.notificationsCount--
            }
        }, e => {
            console.error(e);
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

    acceptPermissionRequest(notification: any): void {
        this.contentService.acceptPermissionRequest(notification.permission_change_request.id).subscribe(r => {
            this.markRead(notification);
        }, e => {
            console.error(e);
            this.snackBarService.openSnackBar(e, 'error')
        });
    }

    rejectPermissionRequest(notification: any): void {
        this.contentService.rejectPermissionRequest(notification.permission_change_request.id).subscribe(r => {
            this.markRead(notification);
        }, e => {
            console.error(e);
            this.snackBarService.openSnackBar(e, 'error')
        });
    }

}

import {Component, OnInit} from '@angular/core';
import {ContentService} from "../../_services/content.service";
import {AuthService} from "../../_services/auth.service";

@Component({
    selector: 'app-faqs',
    templateUrl: './faqs.component.html',
    styleUrl: './faqs.component.scss'
})
export class FaqsComponent implements OnInit {
    user: any;
    faqs: any[] = []
    loading: boolean = true;

    constructor(private authService: AuthService,
                private contentService: ContentService) {
        this.user = this.authService.getUser();
    }

    ngOnInit(): void {
        this.contentService.faqs(this.user?.role?.role_type).subscribe(r => {
            this.faqs = r;
            this.loading = false;
            console.log(r)
        }, e => {
            this.loading = false;
            console.error(e)
        })
    }

    link(url: string, download: boolean, name?: string): void {
        if (download) {
            let downloadName: string = url
            if (name) {
                downloadName = name?.replace(/ /g, "_");
            }
            fetch(url)
                // check to make sure you didn't have an unexpected failure (may need to check other things here depending on use case / backend)
                .then(resp => resp.status === 200 ? resp.blob() : Promise.reject('something went wrong'))
                .then(blob => {
                    const url: string = window.URL.createObjectURL(blob);
                    const a: HTMLAnchorElement = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    // the filename you want
                    a.download = downloadName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(() => function (): void {
                    console.error('catch error')
                    window.open(url, '_blank')?.focus();
                });
        } else {
            window.open(url, '_blank')?.focus();
        }
    }


}

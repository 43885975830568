<footer>
    <div class="top_divider"></div>
    <div class="mat-background-primary w-100 justify-around py-15 footerRow">
        <div class="d-flex flex-row flex-space-evenly gap10 footerBntRow">
            <a [routerLink]="['/privacy']">Privacy
                Policy</a>
            <a [routerLink]="['/terms']">Terms of Use</a>
        </div>
        <div>
            <small>©2024 Virtual Memory Box co-produced with South Tyneside Council</small>
        </div>
        <div>
            <a href="mailto:feedback@virtualmemorybox.org">
                If you have any feedback about the platform please get in touch
            </a>
        </div>
    </div>
</footer>

import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {AdminService} from "../../_services/admin.service";
import {SnackbarService} from "../../_services/snackbar.service";

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrl: './users.component.scss'
})
export class UsersComponent implements AfterViewInit {
    loading: boolean = true;
    displayedColumns: string[] = ['img', 'name', 'username', 'roleType', 'role', 'cohort', 'locality', 'joined', 'view'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
    @ViewChild(MatSort) sort: MatSort | null = null;
    role: string = 'All';
    cohort: string = 'All';
    locality: string = 'All';
    is_active: string = 'All';
    locked: string = 'All';
    typingTimer: any;
    searchTerm: any;
    limit: number = 15;
    page: number = 0;
    count: number = 0;
    direction: string | undefined;
    order_by: string | undefined;
    localities: any[] = [];
    cohorts: any[] = [];
    roles: any[] = [];

    constructor(private adminService: AdminService,
                private snackbarService: SnackbarService) {
        this.getUsers();
    }

    getUsers(): void {
        let filters: any = {
            locality: null,
            cohort: null,
            role: null,
            order_by: null,
            direction: null,
        }
        if (this.locality && this.locality != 'All') {
            filters.locality = this.locality;
        }
        if (this.cohort && this.cohort != 'All') {
            filters.cohort = this.cohort;
        }
        if (this.role && this.role != 'All') {
            filters.role = this.role;
        }
        if (this.locked && this.locked != 'All') {
            filters.locked = this.locked;
        }
        if (this.is_active && this.is_active != 'All') {
            filters.is_active = this.is_active;
        }
        if (this.order_by && this.direction) {
            filters.order_by = this.order_by
        }
        if (this.direction) {
            filters.direction = this.direction
        }

        this.adminService.getAllUsers(this.page, this.limit, this.searchTerm, filters).subscribe(r => {
            this.count = r.count;
            if (this.localities.length == 0) {
                this.localities = r.filter_options.locality
            }
            if (this.cohorts.length == 0) {
                this.cohorts = r.filter_options.cohorts__cohort
            }
            if (this.roles.length == 0) {
                this.roles = r.filter_options.role
            }
            this.dataSource = new MatTableDataSource(r.results);
            this.loading = false;
        }, e => {
            this.loading = false;
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    keyup(): void {
        clearTimeout(this.typingTimer);
        const that: this = this;
        this.typingTimer = setTimeout((): void => {
            that.loading = true;
            this.getUsers()

            if (this.dataSource.paginator) {
                this.dataSource.paginator.firstPage();
            }
        }, 1000);
    }

    clearSearch(): void {
        this.searchTerm = '';
        this.getUsers();
    }

    keydown(): void {
        clearTimeout(this.typingTimer);
    }

    handlePageEvent(e: PageEvent): void {
        this.page = e.pageIndex;
        this.limit = e.pageSize;
        this.getUsers();
    }

    sortData(event: Sort): void {
        this.order_by = event.active;

        if (this.order_by == 'name') {
            this.order_by = 'full_name'
        } else if (this.order_by == 'joined') {
            this.order_by = 'date_joined'
        }
        this.direction = event.direction;
        this.getUsers()
    }
}

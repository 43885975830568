import {AfterViewInit, Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {map, Observable, startWith} from "rxjs";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Loader} from "@googlemaps/js-api-loader";
import {ContentService} from "../../_services/content.service";
import {SnackbarService} from "../../_services/snackbar.service";
import {AuthService} from "../../_services/auth.service";

const loader: Loader = new Loader({
    apiKey: "AIzaSyDTOlh36rmI508E-5UqTd4_wuaXqQRNyCQ",
    version: "weekly",
    libraries: ["places"],
    nonce: (document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement)?.content
});

@Component({
    selector: 'app-memory-form',
    templateUrl: './memory-form.component.html',
    styleUrl: './memory-form.component.scss'
})


export class MemoryFormComponent implements AfterViewInit {
    @ViewChild('placesInput') placesInput: any;
    memoryForm: FormGroup;
    loading: boolean = false;
    timelineId: any = null;
    users: any = [];
    memory: any = null;
    tags: any[] = [];
    @ViewChild('tagInput') tagInput: any;
    filteredTags: Observable<string[]> | any;
    selectedTags: any[] = [];
    tagSearchControl: FormControl = new FormControl();
    readonly minDate: Date = new Date("1800-01-01");
    readonly maxDate: Date = new Date();

    constructor(protected dialogRef: MatDialogRef<MemoryFormComponent>,
                private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: any,
                private contentService: ContentService,
                private snackbarService: SnackbarService,
                private authService: AuthService) {
        this.maxDate.setFullYear(this.maxDate.getFullYear() + 100);

        this.memoryForm = this.formBuilder.group({
            title: ['', Validators.required],
            description: [''],
            address: [''],
            attendees: [''],
            date: [''],
        });

        if (data?.memory) {
            this.memory = data.memory;
            if (this.memory) {
                this.memoryForm.patchValue({
                    title: this.memory.title,
                    description: this.memory.description,
                    date: this.memory.date,
                    attendees: this.memory.attendees,
                });
            }
            this.users = this.memory.users;


        } else if (data?.timeline) {
            this.timelineId = data.timeline;
            this.memoryForm.patchValue({
                date: new Date(),
            });
        }
        this.contentService.getAllTags().subscribe(r => {
            this.tags = r.results;
            if (data?.memory?.tags) {
                for (let i = 0; i < data.memory.tags.length; i++) {
                    for (let tag of this.tags) {
                        if (tag.name == data.memory.tags[i]?.name) {
                            this.selectedTags.push(tag.id);
                        }
                    }
                }
            }
            if (this.memory && this.selectedTags) {
                this.memory.tag = this.selectedTags;
            }
        }, e => {
            console.error(e)
        })
        this.filteredTags = this.tagSearchControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filterTags(value))
            );
    }

    ngAfterViewInit(): void {
        // Load google places
        loader
            .load()
            .then((google): void => {
                const autocomplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(this.placesInput.nativeElement, {componentRestrictions: {country: 'gb'}});
                if (this.memory?.address) {
                    this.placesInput.nativeElement.value = this.memory.address;
                }
                autocomplete.addListener("place_changed", (): void => {
                    const place: google.maps.places.PlaceResult = autocomplete.getPlace();
                    this.memoryForm.patchValue({
                        address: place.formatted_address
                    })
                })
            })
            .catch(e => {
                this.snackbarService.openSnackBar('Google Places error please try again: ' + e, 'error')
            });
    }

    saveDetails(): void {
        this.loading = true;
        let date
        if (this.memoryForm.value.date) {
            date = new Date(this.memoryForm.value.date)
            date = date.toLocaleDateString('en-CA').slice(0, 10);
        }
        let body = {
            address: this.memoryForm.value.address,
            attendees: this.memoryForm.value.attendees,
            date: date,
            description: this.memoryForm.value.description,
            title: this.memoryForm.value.title,
        }
        if (this.memory) {
            this.contentService.patchMemory(this.memory.id, this.clean(body)).subscribe(r => {
                this.loading = false;
                this.dialogRef.close(r);

            }, e => {
                this.snackbarService.openSnackBar(e, 'error');
            })
        } else {
            this.contentService.createMemory(this.timelineId, this.clean(body)).subscribe(r => {
                this.loading = false;
                if (r.id) {
                    for (let tag of this.tags) {
                        if (this.selectedTags.includes(tag.id)) {
                            this.contentService.addTag(r.id, tag.name).subscribe(r => {
                            }, e => {
                                this.snackbarService.openSnackBar(e, 'error')
                                console.error(e)
                            })
                        }
                    }
                    this.dialogRef.close(r);

                } else {
                    this.dialogRef.close(r);
                }
            }, e => {
                this.snackbarService.openSnackBar(e, 'error');
            })
        }
    }

    clean(obj: any) {
        // Remove empty keys
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
                delete obj[propName];
            }
        }
        return obj
    }

    selectTag(event: MatAutocompleteSelectedEvent): void {
        const tag = event as any;
        this.selectedTags.push(tag);
        if (this.tagInput && this.tagInput.nativeElement) {
            this.tagInput.nativeElement.value = '';
        }
        if (this.memory?.id) {
            for (let tag of this.tags) {
                if (tag.id == event) {
                    this.contentService.addTag(this.memory.id, tag.name).subscribe(r => {
                        this.snackbarService.openSnackBar('Tag added successfully', 'success')
                    }, e => {
                        this.snackbarService.openSnackBar(e, 'error')
                        console.error(e)
                    })
                }
            }
        }
    }

    removeTag(tagId: any): void {
        const i = this.selectedTags.findIndex((x: any): boolean => x === tagId);
        if (i != null && i >= 0) {
            this.selectedTags.splice(i, 1);
        }
        if (this.memory?.id) {
            for (let tag of this.tags) {
                if (tag.id == tagId) {
                    this.contentService.deleteTag(this.memory.id, tag.name).subscribe(r => {
                        this.snackbarService.openSnackBar('Tag removed successfully', 'success')
                    }, e => {
                        this.snackbarService.openSnackBar(e, 'error')
                        console.error(e)
                    })
                }
            }
        }
    }

    checkIfInArray(item: any, otherArray: any): boolean {
        let i
        if (otherArray) {
            i = otherArray.findIndex((x: any): boolean => x === item);
        }
        return (i != null && i >= 0);
    }

    private _filterTags(value: string): string[] {
        let filterValue: string = '';
        if (typeof value == 'string') {
            filterValue = value.toLowerCase();
        }
        let results: any[] = this.tags.filter((option: any) => option.name.toLowerCase().includes(filterValue));
        results = results.filter((item: any, index: any): boolean => {
            return (results.indexOf(item) == index);
        });
        return results;
    }
}

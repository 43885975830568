import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDrawerMode} from "@angular/material/sidenav";
import {AuthService} from "../../_services/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {FeedbackComponent} from "../../shared/feedback/feedback.component";
import {ContentService} from "../../_services/content.service";
import {SnackbarService} from "../../_services/snackbar.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-admin-view',
    templateUrl: './admin-view.component.html',
    styleUrl: './admin-view.component.scss'
})
export class AdminViewComponent implements OnInit, AfterViewInit, OnDestroy {
    user: any;
    isMobile: boolean = false;
    minified: boolean = false;
    mode: MatDrawerMode = 'side';
    sideNav: boolean = true;
    notificationNavOpened: boolean = false;
    hijacking: boolean = false;
    readNotifications: any[] = [];
    unreadNotifications: any[] = [];
    notificationsRetrieved: boolean = false;
    notificationsCount: number = 0;
    notificationsPage: number = 0;
    notificationsLimit: number = 10;
    canLoadMore: boolean = true;
    notificationsLoading: boolean = true;
    interval;

    constructor(private authService: AuthService,
                public contentService: ContentService,
                private snackBarService: SnackbarService,
                public dialog: MatDialog,
                private router: Router) {
        this.user = this.authService.getUser();
        if (localStorage.getItem('hijacked')) {
            this.hijacking = true;
        }
        let that = this;
        this.contentService.getNotificationsCount().subscribe(r => {
            this.notificationsCount = r.count;
        }, e => {
            console.error(e);
        })
        this.interval = setInterval(function (): void {
            // Check user every minute to ensure the user hasn't timed out or been forcibly timed out by an admin
            if (that.authService.isLoggedIn()) {
                that.authService.userHeartbeat().subscribe(r => {
                }, e => {
                    console.error(e)
                })
            }
        }, 60000);
        router.events.subscribe((val): void => {
            this.notificationNavOpened = false;
        });
    }

    ngOnDestroy(): void {
        clearTimeout(this.interval);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        this.isMobile = window.innerWidth <= 700;
        if (this.isMobile) {
            this.mode = 'over';
            this.sideNav = false;
        } else {
            this.mode = 'side';
            this.sideNav = true;
        }
    }

    ngOnInit(): void {
        this.isMobile = window.innerWidth <= 700;
        if (this.isMobile) {
            this.mode = 'over';
            this.sideNav = false;
        } else {
            this.mode = 'side';
            this.sideNav = true;
        }
    }

    ngAfterViewInit(): void {
        setTimeout((): void => {
            // This fixes weird gap between sidebar and content
            window.dispatchEvent(new Event('resize'));
        });
    }

    logout(): void {
        this.authService.logout();
    }

    feedback(): void {
        if (this.dialog.openDialogs.length == 0) {
            this.dialog.open(FeedbackComponent, {});
        }
    }

    openNotificationNav(): void {
        if (this.isMobile) {
            this.sideNav = false;
        }
        this.notificationNavOpened = true;
        if (!this.notificationsRetrieved) {
            this.getNotifications()
        }
    }

    getNotifications(): void {
        this.notificationsLoading = true;
        this.contentService.getActiveNotifications().subscribe(r => {
            this.unreadNotifications = r.results;
            this.notificationsLoading = false;
        }, e => {
            console.error(e);
            this.notificationsLoading = false;
            this.snackBarService.openSnackBar(e, 'error')
        })
        this.getUnreadNotes()
    }

    getUnreadNotes(): void {
        this.notificationsLoading = true;
        this.contentService.getNotifications(this.notificationsPage, this.notificationsLimit, true).subscribe(r => {
            if (r.results.length > 0) {
                this.readNotifications = this.readNotifications.concat(r.results);
            }
            this.notificationsPage++
            if (r.count <= this.readNotifications?.length) {
                this.canLoadMore = false;
            }
            this.notificationsLoading = false;
        }, e => {
            this.notificationsLoading = false;
            console.error(e);
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

    markRead(id: number): void {
        this.getNotifications();
    }

    minifySideWindow(): void {
        this.minified = !this.minified
        window.dispatchEvent(new Event('resize'));
    }

    openDropdown(): void {
        this.minified = false;
        this.sideNav = true;
        window.dispatchEvent(new Event('resize'));
    }

}

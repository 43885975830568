<mat-dialog-content role="dialog" aria-modal="true">
    <h1 class="f-18">
        <ng-container *ngIf="memory">Edit</ng-container>
        <ng-container *ngIf="!memory">New</ng-container>
        Album
    </h1>
    <form [formGroup]="memoryForm" class="fieldContainer w-100 mt-10 pt-10">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Title</mat-label>
            <input formControlName="title" matInput placeholder="Title">
            <app-emoji-menu [form]="memoryForm" [input]="'title'" matSuffix></app-emoji-menu>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Description</mat-label>
            <textarea #description formControlName="description" matInput
                      maxlength="400"></textarea>
            <app-emoji-menu [form]="memoryForm" [input]="'description'" matSuffix></app-emoji-menu>
            <mat-hint align="end">{{ description?.value?.length }} / 400</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Who was there</mat-label>
            <input formControlName="attendees" matInput placeholder="Who was there">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Location</mat-label>
            <input #placesInput formControlName="address" matInput placeholder="{{memory?.address}}">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Choose a date</mat-label>
            <input [min]="minDate" [max]="maxDate" [matDatepicker]="picker" formControlName="date" matInput>
            <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="memoryForm.controls['date'].hasError('matDatepickerParse')">
                Invalid date format. Please use MM/DD/YYYY.
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100" color="primary">
            <mat-label>Tags</mat-label>
            <input #tagInput [formControl]="tagSearchControl"
                   [matAutocomplete]="auto"
                   [matChipInputFor]="chipList"
                   matInput
                   placeholder="Select Tags">
            <mat-autocomplete #auto="matAutocomplete"
                              (optionSelected)="selectTag($event.option.value)">
                <ng-container *ngFor="let tag of filteredTags | async">
                    <mat-option *ngIf="!checkIfInArray(tag.id, this.selectedTags)"
                                [value]="tag.id">
                        {{ tag.name }}
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
            <mat-chip-grid #chipList aria-label="Tags Selection">
                <ng-container *ngFor="let tag of this.tags">
                    <mat-chip (removed)="removeTag(tag.id)"
                              *ngIf="checkIfInArray(tag.id, this.selectedTags)" class="smChip">
                        {{ tag.name }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                </ng-container>
            </mat-chip-grid>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="flex-space-around">
    <button color="warn" mat-dialog-close mat-raised-button (keyup.enter)="this.dialogRef.close();">
        Cancel
    </button>
    <button (click)="saveDetails()" (keyup.enter)="saveDetails()" [disabled]="loading || memoryForm.invalid"
            color="primary"
            mat-raised-button>
        Save
    </button>
</mat-dialog-actions>

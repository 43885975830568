<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Locations
        </h3>
    </div>
    <div class="mediumContainer">
        <div class="mat-elevation-z6">
            <div class="flex-row gap-15 bg-white align-items-center flex-space-around justify-content-center">
                <mat-form-field class="noBottomPadding w-100">
                    <mat-label>Search Locations</mat-label>
                    <input (keydown)="keydown()" (keyup)="keyup()"
                           (keyup.enter)="getLocations()"
                           [(ngModel)]="searchTerm"
                           matInput
                           type="text"/>
                    <button (click)="getLocations()" [disabled]="!searchTerm"
                            aria-label="Search"
                            mat-icon-button matPrefix matTooltip="Search Locations">
                        <mat-icon color="primary">
                            search
                        </mat-icon>
                    </button>
                    <button (click)="clearSearch()" *ngIf="searchTerm" aria-label="Clear Search" mat-icon-button
                            matSuffix>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field appearance="outline" class="noBottomPadding w-100 mt-10">
                    <mat-label>New Location</mat-label>
                    <input #locationInput matInput placeholder="Add Location">
                    <button (click)="addLocation(locationInput)" *ngIf="locationInput.value" aria-label="Add Location"
                            mat-icon-button matSuffix matTooltip="Add a new location">
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <table [dataSource]="dataSource" class="responsiveTable" mat-table matSort
                   (matSortChange)="sortData($event)">
                <ng-container matColumnDef="name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
                    <td *matCellDef="let location" mat-cell> {{ location.name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Created Date</th>
                    <td *matCellDef="let location" mat-cell>
                        {{ location.created_date| date }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="view">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>View</th>
                    <td *matCellDef="let location" mat-cell>
                        <button mat-raised-button color="primary" [routerLink]="['/location', location.id]">
                            View Location
                        </button>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row ></tr>
                <tr *matNoDataRow class="mat-row">
                    <td *ngIf="searchTerm" class="mat-cell text-center" colspan="2">
                        No data matching the filter "{{ searchTerm }}"
                    </td>
                    <td *ngIf="!searchTerm && !loading" class="mat-cell text-center" colspan="2">
                        No locations Found
                    </td>
                </tr>
            </table>
        </div>
    </div>
</main>

import {AfterViewInit, Component, HostListener, ViewChild} from '@angular/core';
import {AdminService} from "../../../_services/admin.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackbarService} from "../../../_services/snackbar.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UserSelectComponent} from "../../shared/user-select/user-select.component";

@Component({
    selector: 'app-location',
    templateUrl: './location.component.html',
    styleUrl: './location.component.scss'
})
export class LocationComponent implements AfterViewInit {


    id: any;
    loading: boolean = true;
    location: any;
    shiftIsPressed: boolean = false;
    displayedColumns: string[] = ['checkbox', 'img', 'full_name', 'role'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    @ViewChild(MatSort) sort: MatSort | null = null;
    lastSelect: any = null;
    allChecked: boolean = false;
    typingTimer: any;
    searchTerm: any;
    role: string = 'All'
    status: string = 'All'
    order: any;
    anySelected: boolean = false;
    localityData:any;
    constructor(private adminService: AdminService,
                private route: ActivatedRoute,
                public dialog: MatDialog,
                private snackbarService: SnackbarService,
                private router: Router) {

        this.route.params
            .subscribe(params => {
                this.id = params['id'];
                if (this.id) {
                    this.getLocation();
                    this.adminService.localityReport(this.id).subscribe(r => {
                        this.localityData = r;
                    }, e => {
                        console.error(e);
                        this.snackbarService.openSnackBar(e, 'error');
                    })
                }
            }, error => {
                this.snackbarService.openSnackBar(error, 'error')
                console.error(error);
                this.loading = false;
            });


    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.shiftKey) {
            this.shiftIsPressed = true;
        }
    }

    @HostListener('document:keyup', ['$event'])
    handleKeyboardUpEvent(event: KeyboardEvent): void {
        if (event.shiftKey) {
            this.shiftIsPressed = false;
            this.lastSelect = null;
        }
    }

    ngAfterViewInit(): void {

    }

    checkAll(): void {
        this.anySelected = !this.allChecked;
        for (let i: number = 0; i < this.dataSource.data.length; i++) {
            this.dataSource.data[i].checked = !this.allChecked
        }
    }

    getLocation(): void {
        this.adminService.localityReport(this.id).subscribe(r => {
            this.location = r;
            this.loading = false;
            this.anySelected = false;
            let users = [];
            for (let user of r.users) {
                users.push(user[0])
                //  TODO remove when dylan fixes nested array issue
            }
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.sort = this.sort;

        }, e => {
            console.error(e);
            this.loading = false;
        })
    }

    delete(): void {
        if (confirm('Are you sure you want to delete ' + this.location?.locality.name)) {
            this.adminService.deleteLocality(this.location?.locality.id).subscribe(r => {
                this.router.navigate(['/locations']);
            }, e => {
                console.error(e);
                this.snackbarService.openSnackBar(e, 'error')
            })
        }
    }


    bulkOptions(event: any, user: any): void {
        // Some Shift business
        if (this.shiftIsPressed && !this.lastSelect) {
            this.lastSelect = user.index;
        } else if (this.shiftIsPressed && this.lastSelect) {
            this.shiftIsPressed = false;
            //  shift was set for second time
            //  find out value of previous click then set all users between with the same value
            let i: number = this.dataSource.data.findIndex(x => x.index === this.lastSelect);
            let lastSelectValue: boolean = this.dataSource.data[i].checked;
            // list the indexes of the 2 shifted users
            let indexes: any[] = [(this.lastSelect - 1), (user.index - 1)]
            // Order indexes lowes first
            indexes = indexes.sort();
            for (let i: number = 0; i < this.dataSource.data.length; i++) {
                if (i >= indexes[0] && i <= indexes[1]) {
                    this.dataSource.data[i].checked = lastSelectValue
                }
            }
            this.lastSelect = false;
        }
        let i: number = this.dataSource.data.findIndex(x => x.id === user.id);
        this.dataSource.data[i].checked = event;
        if (!this.dataSource.data[i].checked) {
            this.allChecked = false;
            this.checkIfAnySelected()
        } else {
            this.anySelected = true;
        }
    }

    removeUsers(): void {
        if (confirm('Are you sure you want to remove the selected users from this location?')) {
            let error;
            for (let user of this.dataSource.data) {
                if (user.checked) {
                    this.adminService.removeUserFromLocality(user.id, this.location.locality?.id).subscribe(r => {
                        this.getLocation();
                    }, e => {
                        console.error(e);
                        error = e;
                        this.snackbarService.openSnackBar(e, 'error');
                    })
                }
            }
            if (!error) {
                this.snackbarService.openSnackBar('Users removed successfully', 'success');
            }
        }
    }


    sortData(sort: Sort) {

    }


    checkIfAnySelected(): void {
        this.anySelected = false;
        for (let i: number = 0; i < this.dataSource.data.length; i++) {
            if (this.dataSource.data[i].checked) {
                this.anySelected = true;
                break
            }
        }
    }

    addUsersModal(): void {
        if (this.dialog.openDialogs.length == 0) {
            const dialogRef: MatDialogRef<UserSelectComponent> = this.dialog.open(UserSelectComponent, {
                data: {
                    title: 'Add users to ' + (this.location?.locality?.name || 'Location'),
                    filter: {name: 'locality', value: '!' + this.location?.locality.id}
                },
            });
            dialogRef.afterClosed().subscribe((resp): void => {
                if (resp) {
                    this.addUsers(resp)
                }
            });
        }
    }

    addUsers(selectedUsers: any[]): void {
        let error;
        for (let user of selectedUsers) {
            this.adminService.addUserToLocality(user.id, this.location.locality?.id).subscribe(r => {
                this.getLocation();
            }, e => {
                console.error(e)
                error = e;
                this.snackbarService.openSnackBar(e, 'error');
            })
        }
        if (!error) {
            this.snackbarService.openSnackBar('Users added successfully', 'success');
        }

    }


}

import {Component} from '@angular/core';
import {ContentService} from "../_services/content.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {LightboxComponent} from "../shared/lightbox/lightbox.component";
import {PageEvent} from "@angular/material/paginator";
import {SnackbarService} from "../_services/snackbar.service";
import {Location} from "@angular/common";
import {AuthService} from "../_services/auth.service";

@Component({
    selector: 'app-favourites',
    templateUrl: './favourites.component.html',
    styleUrl: './favourites.component.scss'
})
export class FavouritesComponent {
    id: number = 0;
    media: any[] = [];
    permission: string = 'No permission';
    page: number = 0;
    limit: number = 15;
    user: any;
    loading: boolean = true;
    count: number = 0;
    subtitle: string = 'Your favourite memories';

    constructor(
        private contentService: ContentService,
        public dialog: MatDialog,
        private snackBarService: SnackbarService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private snackbarService: SnackbarService,
        private location: Location,
        private router: Router,
        private authService: AuthService
    ) {
        if (window.innerWidth < 1600 && window.innerWidth > 1100) {
            this.limit = 16;
        }
        this.route.params
            .subscribe(params => {
                this.id = params['id'];

                if (this.id) {
                    this.subtitle = 'Favourite memories';
                    this.contentService.getUserProfile(this.id).subscribe(r => {
                        this.subtitle = r.full_name + "'s favourite memories"
                    }, e => {
                        this.router.navigate(['/people']);
                        this.snackBarService.openSnackBar(e, 'error')
                    })
                }
                if (params['index']) {
                    this.viewImage(params['index'])
                }
            }, error => {
                this.snackBarService.openSnackBar(error, 'error')
                console.error(error);
            });

        this.user = this.authService.getUser();
        this.getMedia();
    }


    getMedia(): Promise<any> {
        return new Promise((resolve, reject): void => {
            this.loading = true;
            this.contentService.getFavouriteMedia(this.page, this.limit, this.id).subscribe(r => {
                this.count = r.count;
                let timelineId
                this.media = [];
                for (let media of r.results) {
                    timelineId = media.memory.timeline.id
                    if (media.media.document) {
                        // Remove the ability to download the file to stop s3 timeout issue
                        media.media.safeDocument = this.safeUrl(media.media.document + "#toolbar=0");
                    }
                    this.media.push(media.media)
                }
                this.loading = false;

                if (timelineId) {
                    this.contentService.viewTimelinePermissions(timelineId).subscribe(
                        r => {
                            for (let permission of r) {
                                if (permission.user.id == this.user.id) {
                                    this.permission = permission.permission
                                }
                            }
                            if (!this.permission || this.permission == 'No permission') {
                                this.snackbarService.openSnackBar('Sorry you do not have permission to view this memory', 'error');
                                this.router.navigate(['/noAccess']);
                            }
                        }, e => {
                            this.snackbarService.openSnackBar(e, 'error');
                            this.router.navigate(['/noAccess']);
                        })
                }

                resolve('Success')
            }, e => {
                this.snackbarService.openSnackBar(e, 'error')
                this.loading = false;
                reject(e)
            })
        });
    }

    back(): void {
        history.back();
    }

    viewImage(i: number): void {
        // stop all active media
        document.querySelectorAll('video').forEach(vid => vid.pause());
        document.querySelectorAll('audio').forEach(vid => vid.pause());

        let isUsersOwnFavourites: boolean = this.id == null;
        this.dialog.closeAll();
        if (this.media[i]) {
            const dialogRef: MatDialogRef<LightboxComponent> = this.dialog.open(LightboxComponent, {
                data: {
                    media: this.media,
                    index: i,
                    permission: this.permission,
                    canFavourite: isUsersOwnFavourites,
                },
                panelClass: 'fullWidthWindow',
            });
            dialogRef.componentInstance.refresh.subscribe((index): void => {
                // Refresh favourites if when media changes
                this.getMedia().then((): void => {
                    dialogRef.close();
                    this.viewImage(index)
                });
            });
            dialogRef.componentInstance.reFetch.subscribe((): void => {
                // Refresh favourites if when media changes
                this.getMedia().then((): void => {
                    dialogRef.close();
                });
            });

            dialogRef.afterClosed().subscribe((): void => {
                // Remove index from param
                this.location.go('favourites/');
            });
        }
    }

    safeUrl(url: any): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    handlePageEvent(e: PageEvent): void {
        this.page = e.pageIndex;
        this.getMedia();
    }


}

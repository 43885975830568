import {Component} from '@angular/core';
import {SnackbarService} from "../../../_services/snackbar.service";
import {ActivatedRoute, Event, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NewPermissionComponent} from "./new-permission/new-permission.component";
import {ContentService} from "../../../_services/content.service";
import {AdminService} from "../../../_services/admin.service";
import {PageEvent} from "@angular/material/paginator";
import {ConfirmDialogComponent} from "../../../shared/confirm-dialog/confirm-dialog.component";
import {ConfirmationDialogData} from "../../../_types/shared";
import {UpdatePasswordComponent} from "../../shared/update-password/update-password.component";
import {AuthService} from "../../../_services/auth.service";
import {EditNameComponent} from "../../shared/edit-name/edit-name.component";
import {NewMemoryBoxAccessComponent} from "./new-memory-box-access/new-memory-box-access.component";

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrl: './user.component.scss'
})
export class UserComponent {
    imgURL: string | ArrayBuffer = 'assets/images/blankProfile.png';
    profile: any;
    id: number = 0;
    permissions: any[] | null = null;
    boxPermissions: any[] | null = null;
    photoFile: any;
    profileForm: FormGroup;
    permissionRequests: any[] = [];
    timelineId: number = 0;
    timeline: string = ''
    changeRequestLimit: number = 5;
    changeRequestPage: number = 0;
    permissionRequestsCount: number = 0;
    cohorts: any[] = [];
    roles: any[] = [];
    localities: any[] = [];
    loading: boolean = true;
    timelineLink: string = window.location.origin + "/timeline"
    selectedCohorts: any[] = [];
    newCohort: any;
    showCropper: boolean = false;
    imageChangedEvent: any = '';
    currentUser: any;

    constructor(private snackBarService: SnackbarService,
                private route: ActivatedRoute,
                private formBuilder: FormBuilder,
                public dialog: MatDialog,
                private contentService: ContentService,
                private adminService: AdminService,
                private authService: AuthService,
                private router: Router) {
        this.currentUser = this.authService.getUser();
        this.profileForm = this.formBuilder.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            username: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            role: ['', [Validators.required]],
            secondEmail: ['', Validators.email],
            phone: ['', Validators.pattern("^[0-9]*$")],
            reference_number: [''],
            jobTitle: [''],
            dob: [''],
            about: [''],
            locality: [''],
            cohort: [''],
        });
        this.route.params
            .subscribe(params => {
                this.id = params['id'];
                this.timelineId = params['timelineId'];
                if (this.id) {
                    this.adminService.getUser(this.id).subscribe(r => {
                        this.profile = r;
                        console.log(this.profile)
                        this.timeline = r.timeline?.title;
                        this.permissions = r.timeline_access
                            .map(({
                                      timeline_id: timeline,
                                      timeline: timeline_name,
                                      timeline_child: user,
                                      ...rest
                                  }: any) => ({
                                timeline,
                                timeline_name,
                                user,
                                ...rest
                            }));
                        this.setProfile(this.profile)
                        this.loading = false;
                        if (this.timelineId) {
                            this.getTimelinePermissions();
                            this.getPermissionChangeRequests();
                            this.timelineLink = this.timelineLink + '/' + this.timelineId
                        } else {
                            this.contentService.getUserTimeline(this.id).subscribe(r => {
                                this.timelineId = r.id;
                                this.timelineLink = this.timelineLink + '/' + this.timelineId
                                this.getTimelinePermissions();
                                this.getPermissionChangeRequests()
                            }, e => {
                                // this.snackBarService.openSnackBar('User has no connected Memory Box', 'error')
                            })
                        }
                    }, e => {
                        this.router.navigate(['/people']);
                        this.snackBarService.openSnackBar(e, 'error')
                    })


                }
            }, error => {
                this.snackBarService.openSnackBar(error, 'error')
                console.error(error);
            });

        this.adminService.getUserRoles().subscribe(r => {
            this.roles = r.results;
        }, e => {
            this.snackBarService.openSnackBar(e, 'error');
        })
        this.getCohorts();
        this.getLocalities();
    }

    getCohorts(): void {
        this.adminService.getCohorts(0, 1000).subscribe(r => {
            this.cohorts = r.results
        }, e => {
            this.snackBarService.openSnackBar(e, 'error');
        })
    }

    getLocalities(): void {
        this.adminService.getLocalities(0, 1000).subscribe(r => {
            this.localities = r.results
        }, e => {
            this.snackBarService.openSnackBar(e, 'error');
        })
    }

    imageCropped(event: any): void {
        let file: File = new File([event.blob], "profile_picture.png");
        this.getBase64(file).then(data => {
            this.photoFile = data;
        });
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(event.blob);
        const that = this;
        reader.onloadend = function (): void {
            const base64data = reader.result;
            if (base64data) {
                that.imgURL = base64data;
            }
        };
    }

    getTimelinePermissions(): void {
        this.contentService.viewTimelinePermissions(this.timelineId).subscribe(res => {
            this.boxPermissions = res
        }, e => {
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

    exportTimeline(): void {
        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
            panelClass: 'mediumWindow',
            data: {
                showSubmitBtn: true,
                showCancelBtn: true,
                message: 'Are you sure you want export this timeline?',
                title: 'Export Timeline',
                showHeader: true
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.contentService.requestExport(this.timelineId).subscribe(r => {
                    this.snackBarService.openSnackBar(r.message, 'success')
                }, e => {
                    this.snackBarService.openSnackBar(e, 'error')
                })
            }
        });
    }

    updatePassword(): void {
        const dialogRef: MatDialogRef<UpdatePasswordComponent> = this.dialog.open<UpdatePasswordComponent>(UpdatePasswordComponent, {
            panelClass: 'mediumWindow',
            data: {
                profile: this.profile
            }
        });
    }

    setProfile(user: any): void {
        if (user.photo) {
            this.imgURL = user.photo;
        }
        this.selectedCohorts = user.cohorts
            .map(({
                      id: id,
                      name: name,
                      ...rest
                  }: any) => ({
                name,
                id,
            }));
        this.profileForm.patchValue({
            first_name: user.first_name,
            last_name: user.last_name,
            username: user.username,
            jobTitle: user.job_title,
            email: user.email,
            secondEmail: user.secondary_email,
            phone: user.contact_number,
            about: user.about_me,
            dob: user.dob,
            reference_number: user.reference_number,
            locality: user.locality?.id,
            role: user.role?.id,
        })
    }

    removeImage(): void {
        this.imgURL = this.profile.photo || 'assets/images/blankProfile.png';
        this.showCropper = false;
    }

    onFileChanged(event: any): void {
        const file = event.target.files[0]

        var mimeType = file.type;
        if (mimeType.match(/image\/*/) == null) {
            // check if image
            this.snackBarService.openSnackBar("Only images are supported.", 'error')
            return;
        }

        var reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        this.showCropper = true;
        this.imageChangedEvent = event;
        reader.onload = (_event): void => {
            if (reader.result) {
                this.imgURL = reader.result;
            } else {
                this.snackBarService.openSnackBar("Reader result returned null.", 'error')
            }
        }
        this.getBase64(event.target.files[0]).then(data => {
            this.photoFile = data;
        });
    }

    getBase64(file: any): Promise<any> {
        return new Promise(resolve => {
            const reader: FileReader = new FileReader();
            reader.onloadend = (): void => {
                resolve(reader.result);
            };
            return reader.readAsDataURL(file);
        });
    }

    saveProfile(): void {
        this.loading = true;
        let date
        if (this.profileForm.value.dob) {
            date = new Date(this.profileForm.value.dob)
            date = date.toLocaleDateString('en-CA').slice(0, 10);
        }
        let user: any = {
            first_name: this.profileForm.value.first_name,
            last_name: this.profileForm.value.last_name,
            username: this.profileForm.value.username,
            job_title: this.profileForm.value.jobTitle,
            email: this.profileForm.value.email,
            secondary_email: this.profileForm.value.secondEmail,
            contact_number: this.profileForm.value.phone,
            about_me: this.profileForm.value.about,
            dob: date,
            photo: '',
            locality: this.profileForm.value.locality,
            cohorts: [],
            reference_number: this.profileForm.value.reference_number,
            role: this.profileForm.value.role,
        }
        if (this.selectedCohorts && this.selectedCohorts.length > 0) {
            for (let cohort of this.selectedCohorts) {
                user.cohorts.push(cohort.id)
            }
        }
        if (this.photoFile) {
            user.photo = this.photoFile;
        }
        this.adminService.patchUser(this.id, this.clean(user)).subscribe(
            r => {
                this.loading = false;
                this.removeImage()
                this.profile = r;
                if (r.photo) {
                    this.imgURL = user.photo;
                } else {
                    this.imgURL = 'assets/images/blankProfile.png'
                }
                this.snackBarService.openSnackBar('Profile updated', 'success')
            }, e => {
                this.loading = false;
                this.snackBarService.openSnackBar(e, 'error')
            })
    }

    clean(obj: any) {
        // remove empty keys
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
                delete obj[propName];
            }
        }
        return obj
    }


    newRelationship(): void {
        const dialogRef: MatDialogRef<NewPermissionComponent> = this.dialog.open(NewPermissionComponent, {
            data: {
                timeline_id: this.timelineId,
                title: this.timeline,
                currentPermissions: this.boxPermissions
            },
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.getTimelinePermissions()
            }
        });
    }

    newMemoryBoxAccess(): void {
        const dialogRef: MatDialogRef<NewMemoryBoxAccessComponent> = this.dialog.open(NewMemoryBoxAccessComponent, {
            data: {
                user: this.profile,
                title: 'New Memory Box access for ' + this.profile.first_name || this.profile.username || this.profile.email,
                access: this.permissions
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.getUser(this.id).subscribe(r => {
                    this.permissions = r.timeline_access
                        .map(({
                                  timeline_id: timeline,
                                  timeline: timeline_name,
                                  timeline_child: user,
                                  ...rest
                              }: any) => ({
                            timeline,
                            timeline_name,
                            user,
                            ...rest
                        }));
                });
            }
        });
    }

    logOutUser(): void {
        this.adminService.forceLogoutUser(this.id).subscribe(r => {
            this.snackBarService.openSnackBar(r.message, 'success')
        }, e => {
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

    deactivate(active: boolean): void {
        let body = {
            is_active: active,
            deactivate: !active
        }
        let text: string = 're-activate'
        if (!active) {
            text = 'deactivate'
        }

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
            panelClass: 'mediumWindow',
            data: {
                showSubmitBtn: true,
                showCancelBtn: true,
                message: 'Are you sure you want ' + text + ' this account?',
                title: text + ' User',
                showHeader: true
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.patchUser(this.id, body).subscribe(r => {
                    this.snackBarService.openSnackBar('User successfully ' + text + 'd', 'success')
                    this.profile.is_active = active;
                }, e => {
                    this.snackBarService.openSnackBar(e, 'error')
                })
            }
        });
    }

    lock(locked: boolean): void {
        let body = {
            locked: locked
        }
        let text: string = 'unlock'
        if (!locked) {
            text = 'lock'
        }

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
            panelClass: 'mediumWindow',
            data: {
                showSubmitBtn: true,
                showCancelBtn: true,
                message: 'Are you sure you want ' + text + ' this account?',
                title: text + ' User',
                showHeader: true
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.patchUser(this.id, body).subscribe(r => {
                    this.snackBarService.openSnackBar('User successfully ' + text + 'ed', 'success')
                    this.profile.locked = locked;
                }, e => {
                    this.snackBarService.openSnackBar(e, 'error')
                })
            }
        });
    }

    getPermissionChangeRequests(event?: any): void {
        this.adminService.getUserAccessRequests(this.timelineId, this.changeRequestPage, this.changeRequestLimit, event).subscribe(r => {
            this.permissionRequestsCount = r.count;
            this.permissionRequests = r.results;
        }, e => {
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

    changePermissionRequestsOrder(event: Event): void {
        this.getPermissionChangeRequests(event)
    }

    permissionRequestsPageChange(e: PageEvent): void {
        this.changeRequestPage = e.pageIndex;
        this.changeRequestLimit = e.pageSize;
        this.getPermissionChangeRequests();
    }

    removeCohort(cohort: any): void {
        const i: number = this.selectedCohorts.findIndex((x: any) => x.id === cohort.id);
        this.selectedCohorts.splice(i, 1);
    }

    selectCohort(cohort: any): void {
        const i: number = this.cohorts.findIndex((x: any): boolean => x.id == cohort);
        if (i >= 0) {
            this.selectedCohorts.push(this.cohorts[i])
        }
    }

    checkIfInArray(item: any, otherArray: any): boolean {
        const i = otherArray.findIndex((x: any): boolean => x.id === item.id);
        return (i != null && i >= 0);
    }

    newCohortModal(): void {
        const dialogRef: MatDialogRef<EditNameComponent> = this.dialog.open(EditNameComponent, {
            data: {
                title: 'Create Cohort',
            },
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.newCohort(resp).subscribe(r => {
                    this.snackBarService.openSnackBar('New cohort created', 'success');
                    this.getCohorts();
                }, e => {
                    console.error(e);
                    this.snackBarService.openSnackBar(e, 'error')
                })
            }
        });
    }

    newLocalityModal(): void {
        const dialogRef: MatDialogRef<EditNameComponent> = this.dialog.open(EditNameComponent, {
            data: {
                title: 'Create locality',
            },
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.newLocality(resp).subscribe(r => {
                    this.snackBarService.openSnackBar('New locality created', 'success');
                    this.getLocalities();
                }, e => {
                    console.error(e);
                    this.snackBarService.openSnackBar(e, 'error')
                })
            }
        });
    }
}

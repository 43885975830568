import {Component} from '@angular/core';
import {AuthService} from "../_services/auth.service";
import {Router} from "@angular/router";
import {FeedbackComponent} from "../shared/feedback/feedback.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-noAccess',
    templateUrl: './noAccess.component.html',
    styleUrl: './noAccess.component.scss'
})
export class NoAccessComponent {
    constructor(private authService: AuthService,
                private router: Router, public dialog: MatDialog,) {
        this.dialog.closeAll();
    }

    goToHome(): void {
        if (this.authService.isLoggedIn()) {
            //  LoginUser has token can go to auth view
            this.router.navigate(['/timeline']);
        } else {
            //  LoginUser has no token can't go to auth view
            this.router.navigate(['/login']);
        }
    }

    feedback(): void {
        if(this.dialog.openDialogs.length==0) {
            this.dialog.open(FeedbackComponent);
        }
    }

    logout(): void {
        this.authService.logout();
    }
}

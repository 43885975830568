import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {AuthService} from "../_services/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SnackbarService} from "../_services/snackbar.service";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrl: './signup.component.scss'
})
export class SignupComponent implements OnInit {
    user: any;
    signupForm: FormGroup;
    imgURL: string | ArrayBuffer = 'assets/images/blankProfile.png';
    loading: boolean = false;
    photoFile: any;
    pipe: DatePipe = new DatePipe('en-GB'); // Use your own locale
    readonly maxDate: Date = new Date();

    constructor(private router: Router,
                private authService: AuthService,
                public dialog: MatDialog,
                private formBuilder: FormBuilder,
                private snackBarService: SnackbarService,) {
        this.signupForm = this.formBuilder.group({
            first_name: [{value: '', disabled: true}],
            last_name: [{value: '', disabled: true}],
            email: [{value: '', disabled: true}],
            username: [{value: '', disabled: true}],
            about: ['', Validators.required],
            dob: ['', Validators.required],
            emailNotifications: [''],
        });
    }

    ngOnInit(): void {
        this.user = this.authService.getUser();
        if (this.user?.role?.role_type !== 'young_person' || this.user.dob) {
            this.signupForm.get('dob')?.clearValidators();
        }
        this.authService.refreshUser().then((r): void => {
            this.user = r;
            if (this.user.photo) {
                this.imgURL = this.user.photo;
            }
            this.signupForm.patchValue({
                first_name: this.user.first_name,
                last_name: this.user.last_name,
                email: this.user.email,
                username: this.user.username,
                emailNotifications: this.user.email_notifications,
                about: this.user.about_me,
                dob: this.user.dob
            })
        });
    }

    removeImage(): void {
        this.imgURL = 'assets/images/blankProfile.png';
    }

    onFileChanged(event: any): void {
        const file = event.target.files[0]

        var mimeType = file.type;
        if (mimeType.match(/image\/*/) == null) {
            this.snackBarService.openSnackBar("Only images are supported.", 'error')
            return;
        }

        var reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event): void => {
            if (reader.result) {
                this.imgURL = reader.result;
            } else {
                this.snackBarService.openSnackBar("Reader result returned null.", 'error')
            }
        }
        this.getBase64(event.target.files[0]).then(data => {
            this.photoFile = data;
        });
    }

    register(): void {
        this.loading = true;
        let user = {
            about_me: this.signupForm.value.about,
            email_notifications: this.signupForm.value.emailNotifications,
            photo: '',
            has_completed_onboarding: 'true',
            dob: this.pipe.transform(this.signupForm.value.dob, 'yyyy-MM-dd')
        }
        if (this.photoFile) {
            user.photo = this.photoFile;
        }
        this.authService.patchUser(this.clean(user)).subscribe(
            r => {
                this.authService.setUser(r)
                this.router.navigate(['/timeline']);
            }, e => {
                console.error(e)
            })
    }

    clean(obj: any) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
                delete obj[propName];
            }
        }
        return obj
    }

    getBase64(file: any): Promise<any> {
        return new Promise(resolve => {
            const reader: FileReader = new FileReader();
            reader.onloadend = (): void => {
                resolve(reader.result);
            };
            return reader.readAsDataURL(file);
        });
    }
}

<ng-container *ngIf="notification">
    <mat-expansion-panel class="my-10" *ngIf="!read else unreadNotification">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ notification.title }}
            </mat-panel-title>
            <mat-panel-description>
                <small>{{ notification.created_date|dateAgo }}</small>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <p class="p-5"> {{ notification.content }}</p>
        <div class="actionRow">
            <a *ngIf="this.getNotificationLink(notification) && !notification.permission_change_request && !notification.timeline_export && !notification.feedback"
               [href]="this.getNotificationLink(notification)" color="primary"
               mat-raised-button>
                View
                <ng-container *ngIf="notification.memory">Memory</ng-container>
                <ng-container *ngIf="notification.comment">Comment</ng-container>
                <ng-container *ngIf="notification.feedback">Feedback</ng-container>
            </a>

            <ng-container *ngIf="notification.feedback  && this.currentUser.role.role_type == 'admin'">
                <a [routerLink]="['/flaggedMedia', notification.feedback?.id]"
                   color="primary"
                   mat-raised-button>
                    View Feedback
                </a>
            </ng-container>
            <ng-container *ngIf="notification.permission_change_request  && this.currentUser.role.role_type == 'admin'">
                <a [routerLink]="['/permissionRequest', notification.permission_change_request?.id]"
                   color="primary"
                   mat-raised-button>
                    View Permission Request
                </a>
            </ng-container>
            <ng-container *ngIf="notification.timeline_export && this.currentUser.role.role_type == 'admin'">
                <a [routerLink]="['/exports']"
                   color="primary"
                   mat-raised-button>
                    View Exports
                </a>
            </ng-container>
            <button (click)="markRead(notification)" (keyup.enter)="markRead(notification)"
                    color="warn"
                    mat-raised-button>
                Dismiss
            </button>
        </div>
    </mat-expansion-panel>

    <ng-template #unreadNotification>
        <mat-expansion-panel class="my-10">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ notification.title }}
                </mat-panel-title>
                <mat-panel-description>
                    <small>{{ notification.created_date|dateAgo }}</small>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <p class="p-5"> {{ notification.content }}</p>
            <div class="actionRow">
                <a *ngIf="this.getNotificationLink(notification) && !notification.permission_change_request && !notification.timeline_export"
                   [href]="this.getNotificationLink(notification)" color="primary"
                   mat-raised-button>
                    View
                    <ng-container *ngIf="notification.memory">Memory</ng-container>
                    <ng-container *ngIf="notification.comment">Comment</ng-container>
                    <ng-container *ngIf="notification.feedback">Feedback</ng-container>
                </a>
                <ng-container
                        *ngIf="notification.permission_change_request && this.currentUser.role.role_type == 'admin'">
                    <a [routerLink]="['/permissionRequest', notification.permission_change_request?.id]"
                       color="primary"
                       mat-raised-button>
                        View Permission Request
                    </a>
                </ng-container>
                <ng-container *ngIf="notification.timeline_export && this.currentUser.role.role_type == 'admin'">
                    <a [routerLink]="['/exports']"
                       color="primary"
                       mat-raised-button>
                        View Exports
                    </a>
                </ng-container>
            </div>
        </mat-expansion-panel>
    </ng-template>
</ng-container>

import {AfterViewInit, Component, HostListener, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {SnackbarService} from "../../_services/snackbar.service";
import {AdminService} from "../../_services/admin.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../shared/confirm-dialog/confirm-dialog.component";
import {ConfirmationDialogData} from "../../_types/shared";
import {AdminViewComponent} from "../admin-view/admin-view.component";

@Component({
    selector: 'app-invitees',
    templateUrl: './invitees.component.html',
    styleUrl: './invitees.component.scss'
})


export class InviteesComponent implements AfterViewInit {
    loading: boolean = true;
    shiftIsPressed: boolean = false;
    displayedColumns: string[] = ['checkbox', 'img', 'name', 'role', 'inviteDate', 'invitesSent', 'view'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
    @ViewChild(MatSort) sort: MatSort | null = null;
    lastSelect: any = null;
    allChecked: boolean = false;
    typingTimer: any;
    searchTerm: any;
    role: string = 'All'
    status: string = 'All'
    anySelected: boolean = false;

    roles: any[] = [];
    statuses: any[] = [];
    limit: number = 15;
    page: number = 0;
    count: number = 0;
    direction: string | undefined;
    order_by: string | undefined;

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.shiftKey) {
            this.shiftIsPressed = true;
        }
    }

    @HostListener('document:keyup', ['$event'])
    handleKeyboardUpEvent(event: KeyboardEvent): void {
        if (event.shiftKey) {
            this.shiftIsPressed = false;
            this.lastSelect = null;
        }
    }

    constructor(private adminService: AdminService,
                private snackbarService: SnackbarService,
                public dialog: MatDialog,
                protected adminView: AdminViewComponent) {
        this.getInvitees();
    }

    getInvitees(): void {
        let filters: any = {
            status: null,
            role: null,
            order_by: null,
            direction: null,
        }
        if (this.status && this.status != 'All') {
            filters.status = this.status.toLowerCase();
        }
        if (this.role && this.role != 'All') {
            filters.role = this.role;
        }
        if (this.order_by && this.direction) {
            filters.order_by = this.order_by
        }
        if (this.direction) {
            filters.direction = this.direction
        }

        this.adminService.getAllInvites(this.page, this.limit, this.searchTerm, filters).subscribe(r => {
            this.count = r.count;
            if (this.statuses.length == 0) {
                this.statuses = r.filter_options.invite_status
            }
            if (this.roles.length == 0) {
                this.roles = r.filter_options.role
            }
            this.dataSource = new MatTableDataSource(r.results);
            this.loading = false;
        }, e => {
            this.loading = false;
            this.snackbarService.openSnackBar(e, 'error');
        })
    }


    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    checkAll(): void {
        this.anySelected = !this.allChecked;
        for (let i: number = 0; i < this.dataSource.data.length; i++) {
            this.dataSource.data[i].checked = !this.allChecked
        }
    }

    reSendInvite(): void {
        let users: any[] = [];
        for (let i: number = 0; i < this.dataSource.data.length; i++) {
            if (this.dataSource.data[i].checked) {
                users.push(this.dataSource.data[i].id)
            }
        }
        this.adminService.reInviteUsers(users).subscribe(r => {
            this.snackbarService.openSnackBar('Invites successfully re-sent', 'success');
            for (let i: number = 0; i < this.dataSource.data.length; i++) {
                this.dataSource.data[i].checked = false;
            }
        }, e => {
            this.snackbarService.openSnackBar(e, 'error')
            console.error(e);
        })
    }

    bulkOptions(event: any, user: any): void {
        // Some Shift business
        if (this.shiftIsPressed && !this.lastSelect) {
            this.lastSelect = user.index;
        } else if (this.shiftIsPressed && this.lastSelect) {
            this.shiftIsPressed = false;
            //  shift was set for second time
            //  find out value of previous click then set all users between with the same value
            let i: number = this.dataSource.data.findIndex(x => x.index === this.lastSelect);
            let lastSelectValue: boolean = this.dataSource.data[i].checked;
            // list the indexes of the 2 shifted users
            let indexes: any[] = [(this.lastSelect - 1), (user.index - 1)]
            // Order indexes lowes first
            indexes = indexes.sort();
            for (let i: number = 0; i < this.dataSource.data.length; i++) {
                if (i >= indexes[0] && i <= indexes[1]) {
                    this.dataSource.data[i].checked = lastSelectValue
                }
            }
            this.lastSelect = false;
        }
        let i: number = this.dataSource.data.findIndex(x => x.id === user.id);
        this.dataSource.data[i].checked = event;
        if (!this.dataSource.data[i].checked) {
            this.allChecked = false;
            this.checkIfAnySelected()
        } else {
            this.anySelected = true;
        }
    }

    deactivate(): void {
        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
            panelClass: 'mediumWindow',
            data: {
                showSubmitBtn: true,
                showCancelBtn: true,
                message: 'Are you sure you want to deactivate the selected invites and the associated user accounts?',
                title: 'Deactivate Invites',
                showHeader: true
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                let users: any[] = [];
                for (let i: number = 0; i < this.dataSource.data.length; i++) {
                    if (this.dataSource.data[i].checked) {
                        users.push(this.dataSource.data[i].id)
                    }
                }
                this.adminService.deleteInvites(users).subscribe(r => {
                    this.snackbarService.openSnackBar('Users successfully deactivated', 'success');
                    location.reload();
                }, e => {
                    this.snackbarService.openSnackBar(e, 'error')
                    console.error(e);
                })
            }
        });
    }

    handlePageEvent(e: PageEvent): void {
        this.page = e.pageIndex;
        this.limit = e.pageSize;
        this.getInvitees();
    }

    sortData(event: Sort): void {
        this.order_by = event.active;

        if (this.order_by == 'name') {
            this.order_by = 'first_name'
        } else if (this.order_by == 'inviteDate') {
            this.order_by = 'invite_sent_date'
        } else if (this.order_by == 'invitesSent') {
            this.order_by = 'number_of_invites_sent'
        }
        this.direction = event.direction;
        this.getInvitees()
    }

    checkIfAnySelected(): void {
        this.anySelected = false;
        for (let i: number = 0; i < this.dataSource.data.length; i++) {
            if (this.dataSource.data[i].checked) {
                this.anySelected = true;
                break
            }
        }
    }

    keyup(): void {
        clearTimeout(this.typingTimer);
        const that: this = this;
        this.typingTimer = setTimeout((): void => {
            that.loading = true;
            this.getInvitees()

            if (this.dataSource.paginator) {
                this.dataSource.paginator.firstPage();
            }
        }, 1000);
    }

    clearSearch(): void {
        this.searchTerm = '';
        this.getInvitees();
    }

    keydown(): void {
        clearTimeout(this.typingTimer);
    }
}

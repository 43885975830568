<mat-dialog-content id="content" role="dialog" aria-modal="true">
    <h1 class="f-18">
        New Memory Item
    </h1>
    <div class="col-row">
        <mat-form-field *ngIf="files.length <= 0" appearance="outline"
                        class="w-100 noBottomPadding mb-10"
                        color="primary">
            <mat-label>Click here to add a memory</mat-label>
            <ngx-mat-dropzone>
                <input [formControl]="fileCtrl" [multiple]="true" accept="*/*" fileInput mode="append" type="file"/>
            </ngx-mat-dropzone>
            <mat-icon color="primary" matSuffix>cloud_upload</mat-icon>
        </mat-form-field>
        <ng-container *ngFor="let file of files; let i = index">
            <mat-card *ngIf="file.cropping" class="imageCard">
                <image-cropper (imageCropped)="imageCropped(file, $event)"
                               [canvasRotation]="rotation"
                               [imageFile]="file.originalFile"
                               [maintainAspectRatio]="false"
                ></image-cropper>
                <button (click)="rotateCanvas()" (keyup.enter)="rotateCanvas()" class="topRightIcon" mat-mini-fab
                        matTooltip="Rotate Image">
                    <mat-icon>rotate_right</mat-icon>
                </button>
                <mat-card-actions class="flex-space-around">
                    <button (click)="cancelCrop(file)" (keyup.enter)="cancelCrop(file)" color="warn" mat-raised-button>
                        Cancel
                    </button>
                    <button (click)="saveCrop(file)" (keyup.enter)="saveCrop(file)" color="primary" mat-raised-button>
                        Save
                    </button>
                </mat-card-actions>
            </mat-card>
        </ng-container>
        <div class="picsContainer">
            <ng-container *ngFor="let file of files; let i = index">
                <mat-card *ngIf="!file.cropping" class="imageCard">
                    <img *ngIf="!file.preview" [src]="file.isHeic? '/assets/images/image.png' : file.thumbnail"
                         mat-card-image alt="Image Preview">
                    <video controls controlsList="nodownload noplaybackrate" *ngIf="file.preview && file.type =='video'"
                           [src]="file.preview"
                           mat-card-image
                           class="object-contain"></video>
                    <div class="audioControl" *ngIf="file.preview && file.type =='audio'">
                        <app-audio-player [file]="file.preview"
                                          [small]="true"></app-audio-player>
                    </div>

                    <small class="p-10" *ngIf="file.isHeic">You have uploaded a file that cannot be previewed. Don't
                        worry once uploaded you will be able to view it.
                    </small>
                    <button (click)="initiateCrop(file)" (keyup.enter)="initiateCrop(file)"
                            *ngIf="this.hasFileType(file.originalFile?.type, this.croppableImageTypes)"
                            class="topRightIcon"
                            mat-mini-fab matTooltip="Crop Image">
                        <mat-icon>crop</mat-icon>
                    </button>
                    <mat-card-content>
                        <mat-form-field appearance="outline" class="w-100 pt-10">
                            <mat-label>Title</mat-label>
                            <input (ngModelChange)="setTitle(file,$event)" [ngModel]="file.title" matInput
                                   maxlength="100" placeholder="Title">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Description</mat-label>
                            <input (ngModelChange)="setDescription(file,$event)"
                                   [ngModel]="file.description" matInput maxlength="255" placeholder="Description">
                            <app-emoji-menu (setEmoji)="file.description = file.description + $event"
                                            matSuffix></app-emoji-menu>
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>
            </ng-container>
            <mat-card *ngIf="files.length > 0 && loadingProgress == 100" class="cropCard">
                <mat-form-field appearance="outline" class="w-100 noBottomPadding secondDropzone" color="primary">
                    <mat-label>Click here to add a memory</mat-label>
                    <ngx-mat-dropzone>
                        <input [formControl]="fileCtrl" [multiple]="true" accept="*/*" fileInput mode="append"
                               type="file"/>
                    </ngx-mat-dropzone>
                </mat-form-field>
            </mat-card>
        </div>
        <mat-chip-set>
            @for (result of results; track result) {
                <mat-chip (click)="removeResult(result)" (keyup.enter)="removeResult(result)"
                          [color]="result.color">{{ result.name }}
                </mat-chip>
            }
        </mat-chip-set>
    </div>

</mat-dialog-content>
<mat-dialog-actions *ngIf="!this.cropping" class="flex-space-around">
    <button color="warn" mat-dialog-close mat-raised-button (keyup.enter)="this.dialogRef.close();">
        Cancel
    </button>
    <button (click)="saveMedia()" (keyup.enter)="saveMedia()" [disabled]="loadingProgress < 100" color="primary"
            mat-raised-button>
        Save
        <mat-icon *ngIf="loadingProgress < 100">
            <mat-spinner color="accent"></mat-spinner>
        </mat-icon>
    </button>
</mat-dialog-actions>
<mat-card-footer *ngIf="loadingProgress < 100 ">
    <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
</mat-card-footer>

<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            FAQs
        </h3>
    </div>

    <div class="mediumContainer">
        <div class="w-100 d-flex flex-col align-items-center" *ngIf="loading || !loading && faqs.length == 0">
            <mat-spinner color="primary" *ngIf="loading"></mat-spinner>
            <h2 *ngIf="!loading && faqs.length == 0">Sorry no content has been created for your user type yet
                please try again later</h2>
        </div>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let question of faqs">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <strong>{{ question.title }}</strong>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [innerHTML]="question.content" class="faqDesc"></div>
                <div *ngIf="question.attachment || question.link" class="flex-row flex-space-around mt-10">
                    <button (click)="link(question.attachment, false, question.attachment)"
                            (keyup.enter)="link(question.attachment, false, question.attachment)"
                            *ngIf="question.attachment"
                            color="primary" mat-raised-button>Download Attachment
                    </button>
                    <button (click)="link(question.link, false, question.link_title)"
                            (keyup.enter)="link(question.link, false, question.link_title)"
                            *ngIf="question.link"
                            color="primary" mat-raised-button>{{ question.link_title }}
                    </button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </div>
</main>

import {Component} from '@angular/core';
import {Event} from "@angular/router";
import {AdminService} from "../../_services/admin.service";
import {SnackbarService} from "../../_services/snackbar.service";
import {PageEvent} from "@angular/material/paginator";

@Component({
    selector: 'app-permission-requests',
    templateUrl: './permission-requests.component.html',
    styleUrl: './permission-requests.component.scss'
})
export class PermissionRequestsComponent {
    loading: boolean = true;
    permissionRequests: any[] = [];
    typingTimer: any;
    searchTerm: any;
    limit: number = 15;
    page: number = 0;
    count: number | null = null;
    resolved: boolean = false;

    constructor(private adminService: AdminService,
                private snackBarService: SnackbarService) {
        this.getPermissionRequests();
    }

    getPermissionRequests(event?: any): void {

        let filters: any;
        if (this.resolved) {
            filters = {status: 'Accepted,Rejected'}
        } else {
            filters = {status: 'Pending'}
        }
        this.adminService.getAccessRequests(this.page, this.limit, this.searchTerm, event, filters).subscribe(r => {
            if (!this.count) {
                this.count = r.count;
            }
            this.permissionRequests = r.results;
            this.loading = false;
        }, e => {
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

    pageChange(e: PageEvent): void {
        this.page = e.pageIndex;
        this.limit = e.pageSize;
        this.getPermissionRequests();
    }


    changeOrder(event: Event): void {
        this.getPermissionRequests(event)
    }

    keyup(): void {
        clearTimeout(this.typingTimer);
        const that: this = this;
        this.typingTimer = setTimeout((): void => {
            that.loading = true;
            this.getPermissionRequests()

        }, 1000);
    }


    clearSearch(): void {
        this.searchTerm = '';
        this.getPermissionRequests();
    }

    switchFilters(): void {
        this.resolved = !this.resolved;
        this.getPermissionRequests();
    }

    keydown(): void {
        clearTimeout(this.typingTimer);
    }
}

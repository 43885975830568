<main class="mediumContainer">
    <div class="d-flex flex-row">
        <button (click)="back()" (keyup.enter)="back()" aria-label="Back" mat-icon-button matTooltip="Back">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
    </div>
    <div>
        <mat-card class="w-100 main-card">
            <mat-card-header>
                <mat-card-title class="mb-10">Settings</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-tab-group animationDuration="0ms" class="tab-row" fitInkBarToContent>
                    <mat-tab label="Profile">
                        <form [formGroup]="profileForm" class="thirdGrid">
                            <div class="pb-10 px-20">
                                <img [src]="imgURL" alt="Profile Picture" class="w-100 imgControl">
                                <div class="flex-row flex-space-around">
                                    <div>
                                        <button (click)="fileInput.click()" (keyup.enter)="fileInput.click()"
                                                color="primary" mat-raised-button>
                                            <span>Select New Profile Picture</span>
                                            <input #fileInput (change)="onFileChanged($event)"
                                                   accept="image/png, image/jpeg" class="d-none"
                                                   type="file"/>
                                        </button>
                                    </div>
                                    <div *ngIf="imgURL != 'assets/images/blankProfile.png' && imgURL != this.user.photo">
                                        <button (click)="removeImage()" color="warn" mat-raised-button>Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="formGrid">
                                <mat-form-field appearance="outline" color="primary">
                                    <mat-label>First Name</mat-label>
                                    <input [disabled]="this.user?.role?.role_type !== 'admin'" required formControlName="first_name" matInput type="text">
                                    <button aria-label="More info" mat-icon-button matSuffix
                                            matTooltip="If you believe this field is incorrect please contact an administrator to change it">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                    <mat-error *ngIf="profileForm.controls['first_name'].hasError('required')">
                                        This field is required
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="primary">
                                    <mat-label>Last Name</mat-label>
                                    <input [disabled]="this.user?.role?.role_type !== 'admin'" required formControlName="last_name" matInput type="text">
                                    <button aria-label="More info" mat-icon-button matSuffix
                                            matTooltip="If you believe this field is incorrect please contact an administrator to change it">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                    <mat-error *ngIf="profileForm.controls['last_name'].hasError('required')">
                                        This field is required
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="primary">
                                    <mat-label>Email</mat-label>
                                    <input [disabled]="this.user?.role?.role_type !== 'admin'" required formControlName="email" matInput type="email">
                                    <button aria-label="More info" mat-icon-button matSuffix
                                            matTooltip="If you believe this field is incorrect please contact an administrator to change it">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                    <mat-error *ngIf="profileForm.controls['email'].hasError('required')">
                                        This field is required
                                    </mat-error>
                                    <mat-error *ngIf="profileForm.controls['email'].hasError('email')">
                                        Please enter a valid email address
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="primary">
                                    <mat-label>Username</mat-label>
                                    <input [disabled]="this.user?.role?.role_type !== 'admin'" required formControlName="username" matInput type="text">
                                    <button aria-label="More info" mat-icon-button matSuffix
                                            matTooltip="If you believe this field is incorrect please contact an administrator to change it">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                    <mat-error *ngIf="profileForm.controls['username'].hasError('required')">
                                        This field is required
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>About Me</mat-label>
                                    <textarea #description formControlName="about_me" matInput
                                              maxlength="400"></textarea>
                                    <app-emoji-menu [form]="profileForm" [input]="'about_me'"
                                                    matSuffix></app-emoji-menu>
                                    <mat-hint align="end">{{ description?.value?.length }} / 400</mat-hint>
                                    <mat-error *ngIf="profileForm.controls['about_me'].hasError('required')">
                                        This field is required
                                    </mat-error>
                                </mat-form-field>
                                <mat-slide-toggle class="center" color="primary" formControlName="emailNotifications"
                                                  ngDefaultControl>
                                    Also Send Notifications to Email
                                </mat-slide-toggle>
                            </div>
                        </form>
                        <div class="w-100 text-center flex-space-evenly flex-row">
                            <button (click)="saveProfile()" [disabled]="profileForm.invalid" color="accent"
                                    mat-raised-button>
                                Save
                            </button>
                        </div>
                    </mat-tab>
                    <mat-tab label="Update Password">
                        <form [formGroup]="passwordForm" class="otherThirdGrid">
                            <div class="flex-col">
                                <mat-form-field appearance="outline" class="w-100 mb-10" color="primary">
                                    <mat-label>Current Password</mat-label>
                                    <input #currentPassword formControlName="currentPassword" matInput
                                           type="password">
                                    <mat-error (click)="currentPassword.focus()"
                                               *ngIf="passwordForm.controls['currentPassword'].hasError('required')">
                                        This field is required
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100 mb-10" color="primary">
                                    <mat-label>New Password</mat-label>
                                    <input #password formControlName="password" matInput
                                           type="password">
                                    <mat-error (click)="password.focus()"
                                               *ngIf="passwordForm.controls['password'].hasError('required')">
                                        This field is required
                                    </mat-error>
                                    <mat-error (click)="password.focus()"
                                               *ngIf="passwordForm.controls['password'].hasError('pattern')">
                                        The password must match all the requirements
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100 mb-10" color="primary">
                                    <mat-label>Confirm New Password</mat-label>
                                    <input #confirmPassword formControlName="confirmPassword"
                                           matInput type="password">
                                    <mat-error (click)="confirmPassword.focus()"
                                               *ngIf="passwordForm.controls['confirmPassword'].hasError('required')">
                                        This field is required
                                    </mat-error>
                                    <mat-error (click)="confirmPassword.focus()"
                                               *ngIf="passwordForm.controls['confirmPassword'].hasError('pattern')">
                                        The password must match all the requirements
                                    </mat-error>
                                    <mat-hint (click)="confirmPassword.focus()" class="errorMsg"
                                              *ngIf="password.value != confirmPassword.value && !passwordForm.controls['confirmPassword'].hasError('pattern')">
                                        Passwords do not match
                                    </mat-hint>
                                </mat-form-field>
                                <div class="col-12 text-center">
                                    <button (click)="editPassword()"
                                            [disabled]="passwordForm.invalid || password.value != confirmPassword.value"
                                            color="primary"
                                            mat-raised-button>
                                        Update
                                    </button>
                                </div>
                            </div>
                            <app-password-validator [matching]="password.value != confirmPassword.value"
                                                    [password]="password.value"></app-password-validator>
                        </form>
                    </mat-tab>
                    <mat-tab *ngIf="dynamicSettings?.font_accessibility" label="Accessibility">
                        <small>Changing these fields will alter the font on this page temporarily as an example unless
                            saved.</small>
                        <form [formGroup]="accessibilityForm" class="formGrid mt-10">
                            <div>
                                <mat-form-field appearance="outline" class=" w-100" color="primary">
                                    <mat-label>Font</mat-label>
                                    <mat-select (valueChange)="setFont($event)" formControlName="fontFamily">
                                        <mat-option value="roboto">Default</mat-option>
                                        <mat-option value="OpenDyslexicAlta">Dyslexic accessible font</mat-option>
                                        <mat-option value="AtkinsonHyperlegible">Legibility font</mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                            <div class="d-flex flex-col w-100">
                                <mat-label>Font size</mat-label>
                                <mat-slider [displayWith]="formatLabel" discrete max="130" min="100" showTickMarks
                                            step="1">
                                    <input (valueChange)="setFontSize($event)" formControlName="fontSize"
                                           matSliderThumb>
                                </mat-slider>
                            </div>
                        </form>
                        <div class="w-100 text-center mt-10">
                            <button (click)="saveAccessibility()" [disabled]="accessibilityForm.invalid" color="accent"
                                    mat-raised-button>Save
                            </button>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>
</main>

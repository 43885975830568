import {Component, Input} from '@angular/core';
import {ContentService} from "../../_services/content.service";
import {SnackbarService} from "../../_services/snackbar.service";
import {AuthService} from "../../_services/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {AdminService} from "../../_services/admin.service";

@Component({
    selector: 'app-manage-access-table',
    templateUrl: './manage-access-table.component.html',
    styleUrl: './manage-access-table.component.scss'
})
export class ManageAccessTableComponent {
    @Input() permissions: any = null;
    @Input() canRequest: boolean = false;
    @Input() timeline: any = null;
    @Input() child: any = null;
    currentUser: any;
    displayedColumns: string[] = ['profile', 'name', 'role', 'permission'];

    constructor(private contentService: ContentService,
                private snackbarService: SnackbarService,
                public dialog: MatDialog,
                private adminService: AdminService,
                private authService: AuthService,
                private router: Router) {
        this.currentUser = this.authService.getUser();
    }

    requestPermissionUpdate(timelineAccess: any): void {

        if (this.currentUser.role.role_type == 'admin') {
            if (!this.timeline) {
                this.timeline = timelineAccess.timeline;
            }
            let body = {
                user: timelineAccess.user.id,
                permission: timelineAccess.newPermission,
                timeline: this.timeline
            }

            this.adminService.permissionUpdate(timelineAccess.id, body).subscribe(r => {
                timelineAccess.permission = timelineAccess.newPermission;
                timelineAccess.changeRequest = null;
                this.snackbarService.openSnackBar('Permission Updated', 'success')
            }, e => {
                this.snackbarService.openSnackBar(e, 'error')
            })
        } else {
            let body = {
                user: timelineAccess.user.id,
                permission: timelineAccess.newPermission,
                message: timelineAccess.changeRequest
            }

            this.contentService.requestPermissionUpdate(this.timeline, body).subscribe(r => {
                timelineAccess.newPermission = timelineAccess.permission;
                timelineAccess.changeRequest = null;
                this.snackbarService.openSnackBar('Permission Request Sent', 'success')
            }, e => {
                this.snackbarService.openSnackBar(e, 'error')
            })
        }
    }

    viewProfile(id: number): void {
        if (this.currentUser.role.role_type == 'admin') {
            this.router.navigate(['/user', id]);
        } else if (this.currentUser.role.can_access_profiles) {
            this.router.navigate(['/profile', id]);
            this.dialog.closeAll();
        }
    }
}


import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../_services/auth.service";
import {SnackbarService} from "../_services/snackbar.service";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrl: './settings.component.scss'
})

export class SettingsComponent implements OnInit, OnDestroy {
    imgURL: string | ArrayBuffer = 'assets/images/blankProfile.png';
    profileForm: FormGroup;
    passwordForm: FormGroup;
    accessibilityForm: FormGroup;
    dynamicSettings: any;
    photoFile: any;
    user: any;

    constructor(private snackBarService: SnackbarService,
                private formBuilder: FormBuilder, private authService: AuthService,) {

        this.profileForm = this.formBuilder.group({
            first_name: [{value: '', disabled: true}, Validators.required],
            last_name: [{value: '', disabled: true}, Validators.required],
            email: [{value: '', disabled: true}, Validators.required],
            username: [{value: '', disabled: true}, Validators.required],
            about_me: ['', Validators.required],
            emailNotifications: [''],
        });

        this.passwordForm = this.formBuilder.group({
            currentPassword: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!\\s).{8,}$')]],
            confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!\\s).{8,}$')]],
        })
        this.accessibilityForm = this.formBuilder.group({
            fontFamily: ['roboto'],
            fontSize: ['100'],
        })
        this.authService.getDynamicSettings().then((r): void => {
            this.dynamicSettings = r;
            if (r.minimum_password_length) {
                this.passwordForm.controls['password'].setValidators([Validators.required, Validators.minLength(r.minimum_password_length), Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!\\s).{' + r.minimum_password_length + ',}$')]);
                this.passwordForm.controls['confirmPassword'].setValidators([Validators.required, Validators.minLength(r.minimum_password_length), Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!\\s).{' + r.minimum_password_length + ',}$')]);
            }
        });
    }

    ngOnInit(): void {
        this.user = this.authService.getUser();
        this.authService.refreshUser().then((r): void => {
            this.user = r;
            if (this.user.photo) {
                this.imgURL = this.user.photo;
            }
            this.profileForm.patchValue({
                first_name: this.user.first_name,
                last_name: this.user.last_name,
                about_me: this.user.about_me,
                email: this.user.email,
                username: this.user.username,
                emailNotifications: this.user.email_notifications,
            })
            this.accessibilityForm.patchValue({
                fontFamily: this.user.font_family,
                fontSize: (this.user.font_size + 99),
            });
            if (this.user.role.role_type == 'admin') {
                this.profileForm.controls['first_name'].enable();
                this.profileForm.controls['last_name'].enable();
                this.profileForm.controls['email'].enable();
                this.profileForm.controls['username'].enable();
            }
        });
    }

    ngOnDestroy(): void {
        this.authService.refreshUser().then((r): void => {
            this.user = r;
            // If they dont save the accessibility settings then go back to original on page leave
            if (document.documentElement.style.getPropertyValue('--font-size') != this.user.font_size || document.documentElement.style.getPropertyValue('--font-family') != this.user.font_family) {
                if (this.user?.font_size > 1) {
                    document.documentElement.style.setProperty('--font-size', ((this.user.font_size + 99) + '%'));
                } else {
                    document.documentElement.style.setProperty('--font-size', '100%');
                }
                document.documentElement.style.setProperty('--font-family', this.user.font_family);
            }
        });
    }

    back(): void {
        history.back();
    }

    formatLabel(value: number): string {
        // add %
        return `${value}%`;
    }

    removeImage(): void {
        this.imgURL = this.user.photo || 'assets/images/blankProfile.png';
    }

    onFileChanged(event: any): void {
        const file = event.target.files[0]

        var mimeType = file.type;
        if (mimeType.match(/image\/*/) == null) {
            // check if image
            this.snackBarService.openSnackBar("Only images are supported.", 'error')
            return;
        }

        var reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event): void => {
            if (reader.result) {
                this.imgURL = reader.result;
            } else {
                this.snackBarService.openSnackBar("Reader result returned null.", 'error')
            }
        }
        this.getBase64(event.target.files[0]).then(data => {
            this.photoFile = data;
        });
    }

    setFont(event: any): void {
        document.documentElement.style.setProperty('--font-family', event);
    }

    setFontSize(event: any): void {
        document.documentElement.style.setProperty('--font-size', (event + '%'));
    }

    editPassword(): void {
        this.authService.updatePassword({
            current: this.passwordForm.value.currentPassword,
            new: this.passwordForm.value.password,
            new_repeat: this.passwordForm.value.confirmPassword,
        }).subscribe(
            r => {
                this.snackBarService.openSnackBar('Password Updated', 'success')
                this.passwordForm.reset();
            }, e => {
                this.snackBarService.openSnackBar(e, 'error')
            })
    }

    saveAccessibility(): void {
        // display slider as a percentage of scale rather than 0-30
        let font_size: number = (this.accessibilityForm.value.fontSize - 101)
        if (font_size < 1) {
            font_size = 1;
        }
        this.authService.patchUser({
            font_family: this.accessibilityForm.value.fontFamily,
            font_size: font_size
        }).subscribe(
            r => {
                this.snackBarService.openSnackBar('Accessibility settings updated', 'success')
                this.authService.setUser(r)
            }, e => {
                this.snackBarService.openSnackBar(e, 'error')
            })
    }

    saveProfile(): void {
        let user = {
            first_name: null,
            last_name: null,
            email: null,
            username: null,
            about_me: this.profileForm.value.about_me,
            email_notifications: this.profileForm.value.emailNotifications,
            photo: '',
        }
        if (this.photoFile) {
            user.photo = this.photoFile;
        }
        if (this.user?.role?.role_type == 'admin') {
            user['first_name'] = this.profileForm.value.first_name
            user['last_name'] = this.profileForm.value.last_name
            user['email'] = this.profileForm.value.email
            user['username'] = this.profileForm.value.username
        }
        this.authService.patchUser(this.clean(user)).subscribe(
            r => {
                this.authService.setUser(r)
                this.snackBarService.openSnackBar('Profile updated', 'success')
            }, e => {
                this.snackBarService.openSnackBar(e, 'error')
            })
    }

    clean(obj: any) {
        // remove empty keys
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
                delete obj[propName];
            }
        }
        return obj
    }

    getBase64(file: any): Promise<any> {
        return new Promise(resolve => {
            const reader: FileReader = new FileReader();
            reader.onloadend = (): void => {
                resolve(reader.result);
            };
            return reader.readAsDataURL(file);
        });
    }
}

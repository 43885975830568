import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ContentService} from "../../_services/content.service";
import {SnackbarService} from "../../_services/snackbar.service";
import {AuthService} from "../../_services/auth.service";

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrl: './notification.component.scss'
})
export class NotificationComponent {
    @Input() notification: any;
    @Input() read: boolean = false;
    currentUser: any;
    @Output() markReadOutput: EventEmitter<number> = new EventEmitter();

    constructor(public contentService: ContentService,
                private snackBarService: SnackbarService,
                private authService: AuthService,) {
        this.currentUser = this.authService.getUser();
    }

    getNotificationLink(notification: any): string | null {
        let link = null;
        if (notification.feedback) {
            if (notification.feedback.memory_id) {
                link = '/memory/' + notification.feedback.memory_id
                if (notification.feedback.content) {
                    link = link + '/#' + notification.feedback.content;
                }
            }
        } else if (notification.memory) {
            link = '/memory/' + notification.memory.id
        } else if (notification.comment) {
            link = '/memory/' + notification.comment.memory.id
        }
        return link
    }

    markRead(notification: any): void {
        this.contentService.markNotificationRead(notification.id).subscribe(r => {
            this.markReadOutput.emit(notification.id);
        }, e => {
            console.error(e);
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

}
